<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Load Calculation</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
                    
            <JobInformation 
                :id="serviceRequest.id"
                :showJobSummary="false"
                :showProjects="false"
                :showAddress="true"
                :showButtonsCreateEditProject="false"
                :refreshJob="refreshJob"
                v-on:changeAddress="updateAddress"
                v-on:changeProject="updateProject"
            />

            <v-row
                v-if="addressSelected != null && addressSelected != undefined"
            >
                <v-col
                    cols="12"
                    style="padding: 0px !important;"
                >
                    <v-card-text>

                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                Load Calculation Information
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">

                                <v-row>
                                    
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Existing Service Size</label>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.existingServiceSize" 
                                            :rules=[validations.required]
                                            v-mask="'########'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :disabled="disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Square Footage Under A/C</label>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.squareFootageUnder" 
                                            :rules=[validations.required]
                                            v-mask="'########'"
                                            single-line
                                            outlined
                                            :disabled="disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>

                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Number Of A/C Units</label>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.numberACUnits" 
                                            :rules=[validations.required]
                                            v-mask="'########'"
                                            single-line
                                            outlined
                                            :disabled="disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>How many tons each unit</label>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.tonsEachUnit" 
                                            :rules=[validations.required]
                                            v-mask="'########'"
                                            single-line
                                            outlined
                                            :disabled="disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- <label>Breaker Size For Electrical Car Outlet</label> -->
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.electricCar"
                                            label="Do you own an electric car?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeElectricalCarOutlet = setNotApplicable(serviceLoadCalculationRequest.electricCar)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeElectricalCarOutlet" 
                                            :rules=[serviceLoadCalculationRequest.electricCar?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.electricCar == 0 || serviceLoadCalculationRequest.electricCar == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.electricCar == 0 || serviceLoadCalculationRequest.electricCar == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                    
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- <label>Breaker Size For Water Heater</label> -->
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.electricGasWaterHeater"
                                            label="Eletric or Gas Water Heater?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeWaterHeater = setNotApplicable(serviceLoadCalculationRequest.electricGasWaterHeater)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeWaterHeater" 
                                            :rules=[serviceLoadCalculationRequest.electricGasWaterHeater?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.electricGasWaterHeater == 0 || serviceLoadCalculationRequest.electricGasWaterHeater == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.electricGasWaterHeater == 0 || serviceLoadCalculationRequest.electricGasWaterHeater == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- <label>Breaker Size For Tankless Water Heater</label> -->
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.electricTanklessWaterHeater"
                                            label="Eletric Tankless Water Heater?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeTanklessWaterHeater = setNotApplicable(serviceLoadCalculationRequest.electricTanklessWaterHeater)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeTanklessWaterHeater" 
                                            :rules=[serviceLoadCalculationRequest.electricTanklessWaterHeater?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.electricTanklessWaterHeater == 0 || serviceLoadCalculationRequest.electricTanklessWaterHeater == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.electricTanklessWaterHeater == 0 || serviceLoadCalculationRequest.electricTanklessWaterHeater == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                    
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- <label>Breaker Size For Range</label> -->
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.electricGasRange"
                                            label="Eletric or Gas Range?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeGasRange = setNotApplicable(serviceLoadCalculationRequest.electricGasRange)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeGasRange" 
                                            :rules=[serviceLoadCalculationRequest.electricGasRange?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.electricGasRange == 0 || serviceLoadCalculationRequest.electricGasRange == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.electricGasRange == 0 || serviceLoadCalculationRequest.electricGasRange == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- <label>Breaker Size For Dryer</label> -->
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.electricGasDryer"
                                            label="Eletric or Gas Dryer?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeGasDryer = setNotApplicable(serviceLoadCalculationRequest.electricGasDryer)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeGasDryer" 
                                            :rules=[serviceLoadCalculationRequest.electricGasDryer?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.electricGasDryer == 0 || serviceLoadCalculationRequest.electricGasDryer == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.electricGasDryer == 0 || serviceLoadCalculationRequest.electricGasDryer == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                    
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- <label>Breaker Size For Double Oven</label> -->
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.doubleOven"
                                            label="Do you have double oven?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeDoubleOven = setNotApplicable(serviceLoadCalculationRequest.doubleOven)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeDoubleOven" 
                                            :rules=[serviceLoadCalculationRequest.doubleOven?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.doubleOven == 0 || serviceLoadCalculationRequest.doubleOven == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.doubleOven == 0 || serviceLoadCalculationRequest.doubleOven == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>

                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <!-- <label>Breaker Size For Cooktop</label> -->
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.cooktop"
                                            label="Do you have cooktop?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeCooktop = setNotApplicable(serviceLoadCalculationRequest.cooktop)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeCooktop" 
                                            :rules=[serviceLoadCalculationRequest.cooktop?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.cooktop == 0 || serviceLoadCalculationRequest.cooktop == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.cooktop == 0 || serviceLoadCalculationRequest.cooktop == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>

                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.generator"
                                            label="Do you have generator?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeGenerator = setNotApplicable(serviceLoadCalculationRequest.generator)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeGenerator" 
                                            :rules=[serviceLoadCalculationRequest.generator?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.generator == 0 || serviceLoadCalculationRequest.generator == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.generator == 0 || serviceLoadCalculationRequest.generator == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>

                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.solarPanel"
                                            label="Do you have solar panel?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeSolarPanel = setNotApplicable(serviceLoadCalculationRequest.solarPanel)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeSolarPanel" 
                                            :rules=[serviceLoadCalculationRequest.solarPanel?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.solarPanel == 0 || serviceLoadCalculationRequest.solarPanel == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.solarPanel == 0 || serviceLoadCalculationRequest.solarPanel == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>

                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <v-switch 
                                            class="align-Columns-Switchs"
                                            v-model="serviceLoadCalculationRequest.existingPoolService"
                                            label="Existing Pool Service ?"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="serviceLoadCalculationRequest.breakerSizeExistingPoolService = setNotApplicable(serviceLoadCalculationRequest.existingPoolService)"
                                            :disabled="disabledMode"
                                        >
                                        </v-switch>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.breakerSizeExistingPoolService" 
                                            :rules=[serviceLoadCalculationRequest.existingPoolService?validations.required:true]
                                            v-mask="'###'"
                                            suffix="AMPS"
                                            single-line
                                            outlined
                                            :placeholder="serviceLoadCalculationRequest.existingPoolService == 0 || serviceLoadCalculationRequest.existingPoolService == null ? 'Not Applicable' : ''"
                                            :disabled="serviceLoadCalculationRequest.existingPoolService == 0 || serviceLoadCalculationRequest.existingPoolService == null || disabledMode"
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                            </v-card-text>

                        </v-card>
                        
                    </v-card-text>
                </v-col>
            </v-row>

            <v-row
                v-if="addressSelected != null && addressSelected != undefined"
            >
                <v-col
                    cols="12"
                    style="padding: 0px !important;"
                >
                    <v-card-text>

                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                Electrical Panel Pictures
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <canvas id="canvas" hidden></canvas>
                                        <label>Please provide pictures of the electrical panels inside and outside, including breakers and panel schedule</label>
                                        <v-file-input
                                            v-model="loadCalculationFilesPhotos" 
                                            accept="image/*"
                                            small-chips
                                            multiple
                                            outlined
                                            placeholder="Click here to add"
                                            prepend-inner-icon="mdi-paperclip"
                                            prepend-icon=""
                                            @change="resizePreviewImage(loadCalculationFilesPhotos, loadCalculationPictures)"
                                            :disabled="disabledMode"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        v-for="(itemPicture, index) in loadCalculationPictures.filter(pht => pht.deleted == false)"
                                        :key="index"
                                        cols="12"
                                        lg="1"
                                        md="4"
                                        sm="12"
                                    >
                                        <v-card 
                                            max-width="200"
                                            @click="downloadFile(URL_ATTACHMENT, itemPicture.id, itemPicture.fileType, itemPicture.fileName)"
                                        >
                                            <v-img
                                                :src="itemPicture.src"
                                                :lazy-src="itemPicture.src"
                                                aspect-ratio="1"
                                                max-height="200"
                                                max-width="200"
                                            >
                                                <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                    ></v-progress-circular>
                                                </v-row>
                                                </template>
                                            </v-img>
                                            <span class="imageFileName">{{ itemPicture.fileName }}</span>
                                            <v-icon
                                                v-if="!disabledMode"
                                                class="deletePhotoIcon" 
                                                small 
                                                right 
                                                color="var(--color__red)" 
                                                @click="confirmPhotoDelete(loadCalculationPictures, itemPicture)"
                                            >
                                                mdi mdi-delete
                                            </v-icon>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                        
                    </v-card-text>
                </v-col>
            </v-row>

            <v-row
                v-if="addressSelected != null && addressSelected != undefined"
            >

                <v-col
                    cols="12"
                    style="padding: 0px !important;"
                >
                    <v-card-text>

                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                Information Provided By
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Name</label>
                                        <v-text-field
                                            v-model="serviceLoadCalculationRequest.nameUserRegister" 
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            disabled
                                            dense
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                        
                    </v-card-text>
                </v-col>
            </v-row>

            <v-row
                v-if="addressSelected != null && addressSelected != undefined"
            >

                <v-col
                    v-if="isStatusDraftingReview"
                    cols="12"
                    style="padding: 0px !important;"
                >
                    <v-card-text>

                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                {{ isStatusDraftingEngineering ? 'Download / Upload' : 'Download' }}
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        lg="5"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                    class="mx-2"
                                                    v-on="on"
                                                    @click="downloadFile(URL_ATTACHMENT_LOAD_CALCULATION, id, 'application/pdf', 'LoadCalculation.pdf')"
                                                >
                                                    <v-icon left>mdi-file-download-outline</v-icon>
                                                    Download Load Calculation
                                                </v-btn>
                                            </template>
                                            <span>Download Load Calculation</span>
                                        </v-tooltip>

                                    </v-col>
                                    <v-col
                                        style="text-align: center;"
                                        cols="12"
                                        lg="2"
                                        md="12"
                                        sm="12"
                                    >
                                    
                                        <v-divider vertical></v-divider>

                                    </v-col>

                                    <v-col
                                        cols="12"
                                        lg="5"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                    class="mx-2"
                                                    v-on="on"
                                                    @click="chooseEngineer"
                                                >
                                                    Send to Engineering
                                                </v-btn>
                                            </template>
                                            <span>Send to Engineering</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                
                            </v-card-text>

                        </v-card>
                        
                    </v-card-text>
                </v-col>
            </v-row>

            <v-dialog
                v-model="showDialogEngineer"
                transition="dialog-top-transition"
                persistent
                width="500"
                :fullscreen="$vuetify.breakpoint.mobile"
            >
            
                <v-card>
                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                        Choose a Engineer
                    </v-card-title>

                    <v-card-text style="margin-top: 20px;">
                        <v-row>

                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Engineer</label>
                                <v-combobox
                                    v-model="engineerSelected"
                                    :items="listContractor" 
                                    item-text="name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    @change="getListContacts()"
                                    dense
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="listContractorContact != null && listContractorContact != undefined && listContractorContact.length > 0"
                        >
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <label>Choose a Contact</label>
                                <v-combobox
                                    v-model="engineerContactSelected"
                                    :items="listContractorContact"
                                    item-text="nameAndEmail"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <div style="width: 100%; text-align: center;">
                            <v-btn
                                class="mx-2 dialogButtonCancel"
                                outlined
                                @click="showDialogEngineer = false"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                                class="mx-2"
                                outlined
                                @click="sendEmailToEngineer()"
                            >
                                Confirm
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <ActionButtons
                :request="serviceLoadCalculationRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

            <ActionDialog 
                v-on:methodConfirmToCall="dialog.methodConfirm"
                :showDialog.sync="dialog.show"
                :headerTitle="dialog.headerText"
                :bodyText="dialog.bodyText"
                :params="dialog.params"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import JobInformation from "@/components/Services/JobInformation";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT,
        CATEGORY_TEAM, 
        URL_ATTACHMENT, 
        URL_ATTACHMENT_LOAD_CALCULATION 
    } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionDialog,
            JobInformation
        },

        data: () => ({

            URL_ATTACHMENT,
            URL_ATTACHMENT_LOAD_CALCULATION,

            validForm: true,

			serviceRequest: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            serviceLoadCalculationRequest: {
                id: 0,
                idService: 0,
                idAddress: 0,
                existingServiceSize: "",
                squareFootageUnder: "",
                numberACUnits: "",
                tonsEachUnit: "",
                electricCar: 0,
                breakerSizeElectricalCarOutlet: "",
                electricGasWaterHeater: 0,
                breakerSizeWaterHeater: "",
                electricTanklessWaterHeater: 0,
                breakerSizeTanklessWaterHeater: "",
                electricGasRange: 0,
                breakerSizeGasRange: "",
                electricGasDryer: 0,
                breakerSizeGasDryer: "",
                doubleOven: 0,
                breakerSizeDoubleOven: "",
                cooktop: 0,
                breakerSizeCooktop: "",
                generator: 0,
                breakerSizeGenerator: "",
                solarPanel: 0,
                breakerSizeSolarPanel: "",
                existingPoolService: 0,
                breakerSizeExistingPoolService: "",
                nameUserRegister: "",
            },

            projectSelected: null,
            refreshJob: false,

            listAddress: [],
            addressSelected: null,

            loadCalculationFilesPhotos: [],
            loadCalculationPictures: [],

            listPictureDelete: [],
            itemPictureToDelete: {},

            showDialogEngineer: false,
            listContractor: [],
            listContractorContact: [],
            engineerSelected: null,
            engineerContactSelected: null,

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            },

            idServiceProject: {
                default: 0
            },

            disabledMode: {
                default: false
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState', menuGetter: 'menuState' }),

            isStatusDraftingReview() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.status >= 8;
            },

            isStatusDraftingEngineering() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.status == 12;
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("serviceModule/GetById", this.id );
                    this.serviceRequest = response.result;

                    this.refreshJob = !this.refreshJob;
                }
            },

            async getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },

            async updateProject(projectSelected) {

                this.showLoading();

                this.projectSelected = projectSelected;

                console.log('this.projectSelected', this.projectSelected);

                this.hideLoading();
            },

            async clearLoadCalculation() {
                this.serviceLoadCalculationRequest = {
                    id: 0,
                    idService: 0,
                    idAddress: 0,
                    existingServiceSize: "",
                    squareFootageUnder: "",
                    numberACUnits: "",
                    tonsEachUnit: "",
                    electricCar: 0,
                    breakerSizeElectricalCarOutlet: "",
                    electricGasWaterHeater: 0,
                    breakerSizeWaterHeater: "",
                    electricTanklessWaterHeater: 0,
                    breakerSizeTanklessWaterHeater: "",
                    electricGasRange: 0,
                    breakerSizeGasRange: "",
                    electricGasDryer: 0,
                    breakerSizeGasDryer: "",
                    doubleOven: 0,
                    breakerSizeDoubleOven: "",
                    cooktop: 0,
                    breakerSizeCooktop: "",
                    generator: 0,
                    breakerSizeGenerator: "",
                    solarPanel: 0,
                    breakerSizeSolarPanel: "",
                    existingPoolService: 0,
                    breakerSizeExistingPoolService: "",
                    nameUserRegister: "",
                };

                this.loadCalculationFilesPhotos = [];
                this.loadCalculationPictures = [];

                this.listPictureDelete = [];
                this.itemPictureToDelete = {};
            },

            async getLoadCalculation() {

                let idAddress = await this.getIDAddress();

                this.clearLoadCalculation();
                
                let responseLoadCalculation = await this.$store.dispatch("serviceModule/GetLoadCalculationById", { id: this.id, idAddress } );

                if (responseLoadCalculation.result.id != 0) {
                    this.serviceLoadCalculationRequest = responseLoadCalculation.result;

                    if (this.serviceLoadCalculationRequest.breakerSizeElectricalCarOutlet == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeElectricalCarOutlet = this.setNotApplicable(this.serviceLoadCalculationRequest.electricCar);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeWaterHeater == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeWaterHeater = this.setNotApplicable(this.serviceLoadCalculationRequest.electricGasWaterHeater);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeTanklessWaterHeater == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeTanklessWaterHeater = this.setNotApplicable(this.serviceLoadCalculationRequest.electricTanklessWaterHeater);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeGasRange == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeGasRange = this.setNotApplicable(this.serviceLoadCalculationRequest.electricGasRange);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeGasDryer == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeGasDryer = this.setNotApplicable(this.serviceLoadCalculationRequest.electricGasDryer);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeDoubleOven == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeDoubleOven = this.setNotApplicable(this.serviceLoadCalculationRequest.doubleOven);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeCooktop == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeCooktop = this.setNotApplicable(this.serviceLoadCalculationRequest.cooktop);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeGenerator == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeGenerator = this.setNotApplicable(this.serviceLoadCalculationRequest.generator);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeSolarPanel == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeSolarPanel = this.setNotApplicable(this.serviceLoadCalculationRequest.solarPanel);
                    }

                    if (this.serviceLoadCalculationRequest.breakerSizeExistingPoolService == 0) {
                        this.serviceLoadCalculationRequest.breakerSizeExistingPoolService = this.setNotApplicable(this.serviceLoadCalculationRequest.existingPoolService);
                    }

                    
                    this.loadCalculationFilesPhotos = [];
                    this.loadCalculationPictures = [];
                    
                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.LOAD_CALCULATION.value, idKey: this.serviceLoadCalculationRequest.id });


                    let loadCalculationFilesPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.LOAD_CALCULATION_PICTURES.value);
                    if (loadCalculationFilesPhotos != null && loadCalculationFilesPhotos != undefined) {

                        loadCalculationFilesPhotos.forEach(itemPicture => {

                            this.loadCalculationPictures.push({
                                id: itemPicture.id,
                                file: new File([], itemPicture.fileName),
                                fileName: itemPicture.fileName,
                                fileType: itemPicture.fileType,
                                deleted: false,
                                src: `data:image/jpg;base64,${itemPicture.file}`
                            });
                        })
                    }
                }
                else {
                    this.serviceLoadCalculationRequest.nameUserRegister = this.userLoggedGetters.name;
                }
            },

            async getIDAddress() {

                let idAddress = 0;

                if (this.addressSelected != null && this.addressSelected != null) {
                    idAddress = this.addressSelected.id;
                }

                return idAddress;
            },                 

            async updateAddress(addressSelected, listAddress) {

                this.addressSelected = addressSelected;
                this.listAddress = listAddress;

                if (addressSelected != null && addressSelected != undefined) {
                    this.getLoadCalculation();
                }
            },
            
            async confirmPhotoDelete(listPictures, itemPictureToDelete) {

                this.listPictureDelete = listPictures;
                this.itemPictureToDelete = itemPictureToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this image [${itemPictureToDelete.fileName}], confirm your decision?`,
                    methodConfirm: this.deletePhoto,
                    params: 0
                };
            },

            async deletePhoto() {

                this.itemPictureToDelete.deleted = true;
            },

            async cancel() {
                history.go(-1);
            },

            setNotApplicable(switchCheck) {

                if (switchCheck == null || switchCheck == 0) {
                    return "";
                }
            },

            async uploadPictures(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.LOAD_CALCULATION.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async savePictures() {

                let allResult = []

                this.loadCalculationPictures.forEach(async itemPicture => {

                    let result = await this.uploadPictures(itemPicture.id, this.serviceLoadCalculationRequest.id, itemPicture.file, TYPE_DOCUMENT.LOAD_CALCULATION_PICTURES.value, itemPicture.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.LOAD_CALCULATION_PICTURES.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload pictures: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async chooseEngineer() {
                this.listContractor = await this.$store.dispatch("contractorModule/ListByCategory", 1); //All Engineer´s
                this.showDialogEngineer = true;
            },

            async getListContacts() {

                this.listContractorContact = []
                    
                if (this.engineerSelected != null || this.engineerSelected != undefined) {
                    this.listContractorContact = await this.$store.dispatch("contractorModule/ListContacts", this.engineerSelected.id);
                }
            },

            async sendEmailToEngineer() {

                this.showLoading();
                
                let listTeamRequest = [];   
                const idServiceProject = await this.getIDProject();

                listTeamRequest.push({
                    id: 0,
                    idServiceProject,
                    categoryTeam: CATEGORY_TEAM.ENGINEER.value,
                    idUserOrContractor: this.engineerContactSelected.id
                });

                let result = await this.$store.dispatch("serviceModule/AssignTeam", listTeamRequest);

                if (result.success) {

                    let result = await this.$store.dispatch("serviceModule/SendEmailLoadCalculation", idServiceProject);

                    if (result.success) {
                        this.showToast("success", "Success!", "E-mail sent with successfully");
                    }
                    else {
                        this.showToast("error", "Error sending email!", result.message);
                    }
                }
                else {
                    this.showToast("error", "Error to assign team!", result.message);
                }

                this.cancel();

                this.hideLoading();
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {

                    if (this.loadCalculationPictures == null || this.loadCalculationPictures == undefined || this.loadCalculationPictures.length < 3) {
                        this.showToast("error", "Warning!", "You must attach at least 3 pictures of the ELETRICAL PANEL");
                    }
                    else {

                        this.loading = true;
                        this.showLoading();
                        
                        if (this.serviceLoadCalculationRequest.electricCar === null || this.serviceLoadCalculationRequest.electricCar == 0) {
                            this.serviceLoadCalculationRequest.electricCar = 0;
                            this.serviceLoadCalculationRequest.breakerSizeElectricalCarOutlet = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.electricGasWaterHeater === null || this.serviceLoadCalculationRequest.electricGasWaterHeater == 0) {
                            this.serviceLoadCalculationRequest.electricGasWaterHeater = 0;
                            this.serviceLoadCalculationRequest.breakerSizeWaterHeater = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.electricTanklessWaterHeater === null || this.serviceLoadCalculationRequest.electricTanklessWaterHeater == 0) {
                            this.serviceLoadCalculationRequest.electricTanklessWaterHeater = 0;
                            this.serviceLoadCalculationRequest.breakerSizeTanklessWaterHeater = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.electricGasRange === null || this.serviceLoadCalculationRequest.electricGasRange == 0) {
                            this.serviceLoadCalculationRequest.electricGasRange = 0;
                            this.serviceLoadCalculationRequest.breakerSizeGasRange = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.electricGasDryer === null || this.serviceLoadCalculationRequest.electricGasDryer == 0) {
                            this.serviceLoadCalculationRequest.electricGasDryer = 0;
                            this.serviceLoadCalculationRequest.breakerSizeGasDryer = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.doubleOven === null || this.serviceLoadCalculationRequest.doubleOven == 0) {
                            this.serviceLoadCalculationRequest.doubleOven = 0;
                            this.serviceLoadCalculationRequest.breakerSizeDoubleOven = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.cooktop === null || this.serviceLoadCalculationRequest.cooktop == 0) {
                            this.serviceLoadCalculationRequest.cooktop = 0;
                            this.serviceLoadCalculationRequest.breakerSizeCooktop = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.generator === null || this.serviceLoadCalculationRequest.generator == 0) {
                            this.serviceLoadCalculationRequest.generator = 0;
                            this.serviceLoadCalculationRequest.breakerSizeGenerator = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.solarPanel === null || this.serviceLoadCalculationRequest.solarPanel == 0) {
                            this.serviceLoadCalculationRequest.solarPanel = 0;
                            this.serviceLoadCalculationRequest.breakerSizeSolarPanel = 0;
                        }
                        
                        if (this.serviceLoadCalculationRequest.existingPoolService === null || this.serviceLoadCalculationRequest.existingPoolService == 0) {
                            this.serviceLoadCalculationRequest.existingPoolService = 0;
                            this.serviceLoadCalculationRequest.breakerSizeExistingPoolService = 0;
                        }

                        let idAddress = await this.getIDAddress();

                        this.serviceLoadCalculationRequest.idService = this.id;
                        this.serviceLoadCalculationRequest.idAddress = idAddress;

                        const result = await this.$store.dispatch("serviceModule/CreateUpdateLoadCalculation", this.serviceLoadCalculationRequest);

                        if (result.success === true) {

                            this.serviceLoadCalculationRequest.id = result.id;

                            if (await this.savePictures()) {

                                this.showToast("success", "Success!", result.message);
                                this.cancel();
                            }
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

</style>

<style>

    .v-text-field__suffix {
        font-size: 12px !important;
        font-weight: bold;
    }
    
    .v-file-input > .v-input__control {
        height: 55px !important;
    }

    .align-Columns-Switchs {
        display: flex;
        align-content: center;
        align-items: center;
        margin-top: 30px !important;
    }
</style>