<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="12"
                lg="2"
                md="4"
                sm="12"
                style="display: flex; align-items: center;"
            >
                <h3>Job Specifics - {{ serviceRequest.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="12"
                lg="7"
                md="4"
                sm="12"
                :style="showImportantMessage ? 'display: flex; align-items: center;' : 'display: grid;' "
            >
                <div 
                    v-if="showImportantMessage"
                    class="importantMessage"
                > 
                    <v-icon 
                        large
                        color="white"
                        right 
                    >
                        mdi mdi-exclamation-thick
                    </v-icon>
                    {{ serviceRequest.importantMessage }}
                </div>
                
                <v-tooltip 
                    v-if="canCreateImportantMessage"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            v-on="on"
                            right 
                            @click="showDialogImportantMessage"
                        >
                            mdi mdi-pen
                        </v-icon>
                    </template>
                    <span>Add New Message</span>
                </v-tooltip>

                <v-tooltip 
                    v-if="showImportantMessage && canCreateImportantMessage"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-if="showImportantMessage"
                            v-on="on"
                            right 
                            @click="deleteImportantMessage"
                        >
                            mdi mdi-delete
                        </v-icon>
                    </template>
                    <span>Clear message</span>
                </v-tooltip>
                    
            </v-col>
            <v-col 
                cols="12"
                lg="3"
                md="4"
                sm="12"
                style="align-content: center; text-align: right"
            >
                <v-chip
                    :color="getStatusColor(serviceProjectResponse.status)"
                    outlined
                    dark
                    link
                    @click="goToPipeline()"
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >
                <JobInformation 
                    :id="serviceRequest.id"
                    :project="projectSelected"
                    :refreshJob="refreshJob"
                    :showJobSummary="true"
                    :showButtonsCreateEditProject="true"
                    :showButtonsCreateEditVersion="true"
                    :showClassification="true"
                    :showDisabledClassification="false"
                    :canChangeSupervisor="true"
                    :showQuickAccess="isProfileAdmin || isSalesPerson"
                    :showQuickAccessAsDocument="isProfileAdmin"
                    :showPayments="isProfileAdmin"
                    :managePayments="isProfileAdmin"
                    v-on:changeProject="updateProject"
                />
            </v-col>
        </v-row>
        
        <v-tabs
            v-if="projectSelected != null && projectSelected != undefined"
            v-model="tab"
            fixed-tabs
        >
            <v-tab key="1" @click="getListNotes()">
                Notes
            </v-tab>

            <v-tab key="2">
                Documents
            </v-tab>

            <v-tab key="3" @click="getListPermittings()">
                Permits
            </v-tab>

            <v-tab key="4" @click="updateTechSpec()">
                Technical Specification
            </v-tab>

            <v-tab key="5" v-if="userLoggedGetters.profile.budgetControl != 1">
                Activities
            </v-tab>

            <v-tab key="6" @click="getHistoryRegister()">
                History
            </v-tab>

            <v-tab key="7">
                Details
            </v-tab>

            <v-tab key="8" @click="getCalendarHistoryRegister()">
                Calendar History
            </v-tab>
        </v-tabs>
            
        <v-tabs-items 
            v-if="projectSelected != null && projectSelected != undefined"
            v-model="tab"
        >
            <!-- NOTES -->
            <v-tab-item 
                key="1"
                style="padding: 20px;"
            >
                <v-row>
                    <v-col 
                        v-if="userLoggedGetters.profile.systemAdmin == 1"
                        cols="12"
                        lg="3"
                        md="6"
                        sm="12"
                    >
                        <label>Department</label>
                        <v-combobox
                            v-model="noteSearchModuleSelected"
                            :items="listModules"
                            item-text="moduleDescription"
                            item-value="id"
                            clearable
                            outlined
                            dense
                            @change="getListNotes(); filterStages(false)"
                        ></v-combobox>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="3"
                        md="6"
                        sm="12"
                    >
                        <label>Stage</label>
                        <v-combobox
                            v-model="noteSearchStageSelected"
                            :items="listStatusFiltered"
                            item-text="statusDescription"
                            item-value="id"
                            clearable
                            outlined
                            dense
                            @change="getListNotes();"
                        ></v-combobox>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="3"
                        md="6"
                        sm="12"
                    >
                        <label>Product</label>
                        <v-combobox
                            v-model="userSelected"
                            :items="listProjectItems"
                            item-text="description"
                            item-value="idProduct"
                            clearable
                            outlined
                            dense
                            @change="getListNotes();"
                        ></v-combobox>
                    </v-col>
                    <v-col 
                        v-if="userLoggedGetters.profile.constructionUser == 1"
                        cols="12"
                        lg="3"
                        md="6"
                        sm="12"
                    >
                        <label>User</label>
                        <v-combobox
                            v-model="userSelected"
                            :items="listUser"
                            item-text="name"
                            item-value="id"
                            clearable
                            outlined
                            dense
                            @change="getListNotes();"
                        ></v-combobox>
                    </v-col>
                </v-row>
                <v-row>                    
                    <v-col 
                        cols="12"
                        lg="9"
                        md="6"
                        sm="12"
                    >
                        <label>Quick Search</label>
                        <v-form @submit.prevent="getListNotes">
                            <v-text-field
                                v-model="filterNotes.fastSearch"
                                prepend-inner-icon="mdi mdi-magnify"
                                outlined
                                single-line
                                @click:prepend-inner="getListNotes"
                                dense
                            ></v-text-field>
                        </v-form>
                    </v-col>

                    <v-col
                        style="margin-bottom: 10px; display: flex; align-content: center; align-items: center; flex-direction: row-reverse;"
                        cols="12"
                        lg="3"
                        md="6"
                        sm="12"
                    >
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-btn 
                                    class="mx-2"
                                    v-on="on"
                                    @click="showDialogNoteAndClearFields()"
                                >
                                    Add New Note
                                </v-btn>
                            </template>
                            <span>Add New Note</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col  
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <v-data-table
                            :headers="headersNote"
                            :items="filteredNotesSearch"
                            no-data-text="No notes registered"
                            hide-default-footer
                        >
                            <template v-slot:item.dateRegisterFormatted="{ item }">
                                <span
                                    :style="item.unread == 1 ? 'font-weight: bold;' : ''"
                                >
                                    {{ item.dateRegisterFormatted }}
                                </span>
                            </template>

                            <template v-slot:item.description="{ item }">
                                <span
                                    :style="item.unread == 1 ? 'font-weight: bold;' : ''"
                                >
                                    {{ item.description }}
                                </span>
                            </template>

                            <template v-slot:item.statusDescription="{ item }">
                                <span
                                    :style="item.unread == 1 ? 'font-weight: bold;' : ''"
                                >
                                    {{ item.statusDescription }}
                                </span>
                            </template>

                            <template v-slot:item.productDescription="{ item }">
                                <span
                                    :style="item.unread == 1 ? 'font-weight: bold;' : ''"
                                >
                                    {{ item.productDescription }}
                                </span>
                            </template>

                            <template v-slot:item.userName="{ item }">
                                <span
                                    :style="item.unread == 1 ? 'font-weight: bold;' : ''"
                                >
                                    {{ item.userName }}
                                </span>
                            </template>
                            
                            <template v-slot:item.action="{item}">
                                <ActionList 
                                    :id="item.id" 
                                    :showButtons="{
                                        edit: (item.idUser == userLoggedGetters.id || userLoggedGetters.profile.systemAdmin == 1) ? true : false,
                                        delete: (item.idUser == userLoggedGetters.id || userLoggedGetters.profile.systemAdmin == 1) ? true : false,
                                        viewPhotos: true,
                                    }"
                                    @customActionEdit="showDialogNoteAndFillFields"
                                    @confirmDelete="confirmNoteDelete"
                                    @viewPhotos="showPhotos"
                                />
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-dialog
                    style="overflow: hidden !important;"
                    v-model="showDialogNote"
                    transition="dialog-top-transition"
                    persistent
                    width="70%"
                    :fullscreen="$vuetify.breakpoint.mobile"
                >
                
                    <v-card>
                        <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                            Note
                        </v-card-title>

                        <v-card-text style="margin-top: 20px;">
                            <v-form
                                ref="formNote"
                                v-model="validFormNote"
                                lazy-validation
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Department</label>
                                        <v-combobox
                                            v-model="noteModuleSelected"
                                            :items="listModules" 
                                            :rules=[validations.required]
                                            item-text="moduleDescription"
                                            item-value="id"
                                            clearable
                                            outlined
                                            dense
                                            @change="filterStages(true)"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Stage</label>
                                        <v-combobox
                                            v-model="noteStageSelected"
                                            :items="listStatusFiltered"
                                            item-text="statusDescription"
                                            item-value="id"
                                            clearable
                                            outlined
                                            dense
                                        ></v-combobox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="4"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Product</label>
                                        <v-combobox
                                            v-model="noteProductSelected"
                                            :items="listProjectItems"
                                            item-text="description"
                                            item-value="idProduct"
                                            clearable
                                            outlined
                                            dense
                                        ></v-combobox>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-switch 
                                            v-model="showToCustomer"
                                            label="Show to Customer"
                                            :value=1
                                            color="var(--color__main)"
                                        >
                                        </v-switch>
                                    </v-col>
                                    <v-col 
                                        v-if="showToCustomer == 1"
                                        cols="12"
                                        lg="4"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-menu
                                            ref="mnuExpirationDate"
                                            v-model="mnuExpirationDate"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <label>Expiration Date</label>
                                                <v-text-field
                                                    v-model="expirationDateFormatted"
                                                    prepend-inner-icon="mdi-calendar"
                                                    v-bind="attrs"
                                                    :rules=[validations.required]
                                                    @blur="expirationDate = parseDate(expirationDateFormatted)"
                                                    readonly
                                                    outlined
                                                    single-line
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="expirationDate"
                                                no-title
                                                @input="mnuExpirationDate = false"
                                                :min="today"
                                                color="primary lighten-1"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col 
                                        v-if="showToCustomer == 1"
                                        cols="12"
                                        lg="4"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Classification</label>
                                        <v-combobox
                                            v-model="classificationNotesSelected"
                                            :items="listClassificationNotes"
                                            item-text="classificationDescription"
                                            item-value="id"
                                            clearable
                                            outlined
                                            dense
                                        ></v-combobox>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <canvas id="canvas" hidden></canvas>
                                        <label>Photos</label>
                                        <v-file-input
                                            v-model="noteFilesPhotos" 
                                            accept="image/*"
                                            small-chips
                                            multiple
                                            outlined
                                            placeholder="Click here to add"
                                            prepend-inner-icon="mdi-paperclip"
                                            prepend-icon=""
                                            @change="resizePreviewImage(noteFilesPhotos, notePhotos)"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>

                                <v-row
                                    :style="notePhotos != null && notePhotos != undefined && notePhotos.length > 0 ? 'margin-bottom: 20px;' : ''"
                                >
                                    <v-col
                                        v-for="(itemPhoto, index) in notePhotos.filter(pht => pht.deleted == false)"
                                        :key="index"
                                        cols="12"
                                        xl="2"
                                        lg="3"
                                        md="4"
                                        sm="12"
                                    >
                                        <v-card max-width="200">
                                            <v-img
                                                :src="itemPhoto.src"
                                                :lazy-src="itemPhoto.src"
                                                aspect-ratio="1"
                                                max-height="200"
                                                max-width="200"
                                            >
                                                <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                    ></v-progress-circular>
                                                </v-row>
                                                </template>
                                            </v-img>
                                            <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                            <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(itemPhoto)">mdi mdi-delete</v-icon>
                                        </v-card>
                                    </v-col>
                                </v-row>


                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <label>Note</label>
                                        
                                        <v-textarea
                                            v-model="note"
                                            :rules=[validations.required]
                                            single-line
                                            rows="3"
                                            row-height="30"
                                            outlined
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <div style="width: 100%; text-align: center;">
                                <v-btn
                                    class="mx-2 buttonCancelDialog"
                                    style=""
                                    outlined
                                    @click="showDialogNote = false"
                                >
                                    Cancel
                                </v-btn>

                                <v-btn
                                    class="mx-2"
                                    outlined
                                    @click="saveNote()"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-tab-item>

            <!-- DOCUMENTS -->
            <v-tab-item 
                key="2"
                style="padding: 20px;"
            >
                <v-row justify="center">

                    <v-expansion-panels popout>

                        <!-- CUSTOMER -->
                        <v-col
                            v-if="userLoggedGetters.profile.systemAdmin == 1"
                            style="padding: 10px;"
                            lg="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-file-document-multiple-outline
                                            </v-icon>
                                            Customer
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-simple-table
                                                fixed-header
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                File Name
                                                            </th>
                                                            <th class="text-left">
                                                                Created On
                                                            </th>
                                                            <th class="text-left">
                                                                Created By
                                                            </th>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in listCustomerFiles" :key="index"
                                                        >
                                                            <td>{{ item.fileName }}</td>
                                                            <td>{{ item.createdOn }}</td>
                                                            <td>{{ item.userName }}</td>
                                                            <td></td>
                                                            <td>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip 
                                                                            link 
                                                                            color="var(--color__main)"
                                                                            style="color: white;"
                                                                            v-on="on"
                                                                            small 
                                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                                        >
                                                                            Download
                                                                        </v-chip>
                                                                    </template>
                                                                    <span>Click to download [{{ item.fileName }}]</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                    
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>

                        <!-- SCOPE OF WORK -->
                        <v-col
                            v-if="showEspecialDocuments"
                            style="padding: 10px;"
                            lg="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-file-document-check-outline
                                            </v-icon>
                                            Scope of Work
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-simple-table
                                                fixed-header
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                File Name
                                                            </th>
                                                            <th class="text-left">
                                                                Created On
                                                            </th>
                                                            <th class="text-left">
                                                                Created By
                                                            </th>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in listProposalFiles" :key="index"
                                                        >
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''" >{{ item.fileName }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.createdOn }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.userName }}</td>
                                                            <td> 
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon 
                                                                            v-if="index == 0" 
                                                                            small 
                                                                            color="green"
                                                                            v-on="on"
                                                                        >
                                                                            mdi-check-circle-outline
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Latest File</span>
                                                                </v-tooltip>
                                                            </td>
                                                            <td>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip 
                                                                            link 
                                                                            color="var(--color__main)"
                                                                            style="color: white;"
                                                                            v-on="on"
                                                                            small 
                                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                                        >
                                                                            Download
                                                                        </v-chip>
                                                                    </template>
                                                                    <span>Click to download [{{ item.fileName }}]</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>

                                            
                                        </v-col>
                                    </v-row>
                                    
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>

                        <!-- CONTRACT -->
                        <v-col
                            v-if="showEspecialDocuments"
                            style="padding: 10px;"
                            lg="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-file-document-check-outline
                                            </v-icon>
                                            Contract
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-simple-table
                                                fixed-header
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                File Name
                                                            </th>
                                                            <th class="text-left">
                                                                Created On
                                                            </th>
                                                            <th class="text-left">
                                                                Created By
                                                            </th>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in listContractFiles" :key="index"
                                                        >
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''" >{{ item.fileName }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.createdOn }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.userName }}</td>
                                                            <td> 
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon 
                                                                            v-if="index == 0" 
                                                                            small 
                                                                            color="green"
                                                                            v-on="on"
                                                                        >
                                                                            mdi-check-circle-outline
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Latest File</span>
                                                                </v-tooltip>
                                                            </td>
                                                            <td>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip 
                                                                            link 
                                                                            color="var(--color__main)"
                                                                            style="color: white;"
                                                                            v-on="on"
                                                                            small 
                                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                                        >
                                                                            Download
                                                                        </v-chip>
                                                                    </template>
                                                                    <span>Click to download [{{ item.fileName }}]</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>

                                            
                                        </v-col>
                                    </v-row>
                                    
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>

                        <!-- HOMEOWNER RESPONSABILITY -->
                        <v-col
                            v-if="showEspecialDocuments"
                            style="padding: 10px;"
                            lg="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-file-document-check-outline
                                            </v-icon>
                                            Homeowner Responsability
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-simple-table
                                                fixed-header
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                File Name
                                                            </th>
                                                            <th class="text-left">
                                                                Created On
                                                            </th>
                                                            <th class="text-left">
                                                                Created By
                                                            </th>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in listHORespFiles" :key="index"
                                                        >
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''" >{{ item.fileName }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.createdOn }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.userName }}</td>
                                                            <td> 
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon 
                                                                            v-if="index == 0" 
                                                                            small 
                                                                            color="green"
                                                                            v-on="on"
                                                                        >
                                                                            mdi-check-circle-outline
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Latest File</span>
                                                                </v-tooltip>
                                                            </td>
                                                            <td>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip 
                                                                            link 
                                                                            color="var(--color__main)"
                                                                            style="color: white;"
                                                                            v-on="on"
                                                                            small 
                                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                                        >
                                                                            Download
                                                                        </v-chip>
                                                                    </template>
                                                                    <span>Click to download [{{ item.fileName }}]</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>

                                            
                                        </v-col>
                                    </v-row>
                                    
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        
                        <!-- ADDENDUM -->
                        <v-col
                            style="padding: 10px;"
                            lg="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-text-box-multiple-outline
                                            </v-icon>
                                            Addendum
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-simple-table
                                                fixed-header
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                File Name
                                                            </th>
                                                            <th class="text-left">
                                                                Created On
                                                            </th>
                                                            <th class="text-left">
                                                                Created By
                                                            </th>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in listAddendumFiles" :key="index"
                                                        >
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''" >{{ item.fileName }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.createdOn }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.userName }}</td>
                                                            <td> 
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon 
                                                                            v-if="index == 0" 
                                                                            small 
                                                                            color="green"
                                                                            v-on="on"
                                                                        >
                                                                            mdi-check-circle-outline
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Latest File</span>
                                                                </v-tooltip>
                                                            </td>
                                                            <td>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip 
                                                                            link 
                                                                            color="var(--color__main)"
                                                                            style="color: white;"
                                                                            v-on="on"
                                                                            small 
                                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                                        >
                                                                            Download
                                                                        </v-chip>
                                                                    </template>
                                                                    <span>Click to Download [{{ item.fileName }}]</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        
                        <!-- LOAD CALCULATION -->
                        <v-col
                            style="padding: 10px;"
                            lg="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-text-box-multiple-outline
                                            </v-icon>
                                            Load Calculation
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-simple-table
                                                fixed-header
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                File Name
                                                            </th>
                                                            <th class="text-left">
                                                                Created On
                                                            </th>
                                                            <th class="text-left">
                                                                Created By
                                                            </th>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in listLoadCalculationFiles" :key="index"
                                                        >
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''" >{{ item.fileName }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.createdOn }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.userName }}</td>
                                                            <td> 
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon 
                                                                            v-if="index == 0" 
                                                                            small 
                                                                            color="green"
                                                                            v-on="on"
                                                                        >
                                                                            mdi-check-circle-outline
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Latest File</span>
                                                                </v-tooltip>
                                                            </td>
                                                            <td>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip 
                                                                            link 
                                                                            color="var(--color__main)"
                                                                            style="color: white;"
                                                                            v-on="on"
                                                                            small 
                                                                            @click="downloadFile(URL_ATTACHMENT_LOAD_CALCULATION, item.id, 'application/pdf', 'LoadCalculation.pdf')"
                                                                        >
                                                                            Download
                                                                        </v-chip>
                                                                    </template>
                                                                    <span>Click to Download [{{ item.fileName }}]</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        
                        <!-- COLOR SELECTION -->
                        <v-col
                            style="padding: 10px;"
                            lg="12"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-text-box-multiple-outline
                                            </v-icon>
                                            Color Selection
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-simple-table
                                                fixed-header
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                File Name
                                                            </th>
                                                            <th class="text-left">
                                                                Created On
                                                            </th>
                                                            <th class="text-left">
                                                                Created By
                                                            </th>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in listColorSelectionFiles" :key="index"
                                                        >
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''" >{{ item.fileName }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.createdOn }}</td>
                                                            <td :style="index == 0 ? 'font-weight: bold' : ''">{{ item.userName }}</td>
                                                            <td> 
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon 
                                                                            v-if="index == 0" 
                                                                            small 
                                                                            color="green"
                                                                            v-on="on"
                                                                        >
                                                                            mdi-check-circle-outline
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Latest File</span>
                                                                </v-tooltip>
                                                            </td>
                                                            <td>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip 
                                                                            link 
                                                                            color="var(--color__main)"
                                                                            style="color: white;"
                                                                            v-on="on"
                                                                            small 
                                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                                        >
                                                                            Download
                                                                        </v-chip>
                                                                    </template>
                                                                    <span>Click to Download [{{ item.fileName }}]</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>

                    </v-expansion-panels>
                </v-row>

            </v-tab-item>

            <!-- PERMITS -->
            <v-tab-item 
                key="3"
                style="padding-top: 50px;"
            >
                <v-row>
                    <v-col
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <div 
                            style="width: 100%; margin: 20px; text-align: center;"
                            v-if="permittingAttachmentNOC != null && permittingAttachmentNOC != undefined"
                        >
                            <v-tooltip
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <v-chip 
                                        link 
                                        color="var(--color__main)"
                                        style="color: white;"
                                        v-on="on"
                                        small 
                                        @click="downloadFile(URL_ATTACHMENT, permittingAttachmentNOC.id, permittingAttachmentNOC.fileType, permittingAttachmentNOC.fileName)"
                                    >
                                        Download [{{ permittingAttachmentNOC.fileName }}]
                                    </v-chip>
                                </template>
                                <span>Click to Download [{{ permittingAttachmentNOC.fileName }}]</span>
                            </v-tooltip>
                        </div>

                        <v-simple-table
                            fixed-header
                        >
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Created On
                                        </th>
                                        <th class="text-left">
                                            Created By
                                        </th>
                                        <th class="text-left">
                                            Responsible
                                        </th>
                                        <th class="text-left">
                                            Permit
                                        </th>
                                        <th class="text-left">
                                            Number
                                        </th>
                                        <th class="text-left">
                                            Sent to City
                                        </th>
                                        <th class="text-left">
                                            Status
                                        </th>
                                        <th class="text-left"></th>
                                        <th class="text-left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in listPermittings" :key="index"
                                    >
                                        <td>{{ item.dateRegisterFormatted }}</td>
                                        <td>{{ item.userNameRegister }}</td>
                                        <td>
                                            <label v-for="(itemResponsible, index) in item.responsibleSelected" :key="index" style="margin-left: 10px; font-weight: normal;">
                                                {{ getResponsible(item, itemResponsible) }}
                                                <br />
                                            </label>
                                        </td>
                                        <td>{{ item.permitTitle }}</td>
                                        <td>{{ item.permitNumber }}</td>
                                        <td>{{ item.sentCityDescription }}</td>
                                        <td>{{ item.statusDescription }}</td>
                                        <td>
                                            <div
                                                style="margin: 10px;"
                                                v-for="(itemFile, index) in listPermittingsAttachments.filter(itemAttach => itemAttach.idPermitting == item.idPermitting)" :key="index"
                                            >
                                                <v-tooltip
                                                    top
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip 
                                                            link 
                                                            color="var(--color__main)"
                                                            style="color: white;"
                                                            v-on="on"
                                                            small 
                                                            @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                                        >
                                                            Download [{{ itemFile.fileName }}]
                                                        </v-chip>
                                                    </template>
                                                    <span>Click to Download [{{ itemFile.fileName }}]</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        
                    </v-col>
                </v-row>
            </v-tab-item>

            <!-- TECHNICAL SPECIFICATION -->
            <v-tab-item 
                key="4"
                style="padding: 20px;"
            >
                <TechnicalSpecification
                    :project="projectSelected"
                    :listProjects="listProjects"
                    :showBackButton="true"
                    :showFinishButton="false"
                    :showCancelButton="false"
                    :showNotificationApprovedOrDenied="true"
                    :forceUpdate="forceTSUpdate"
                />

            </v-tab-item>

            <!-- ACTIVITIES -->
            <v-tab-item 
                v-if="userLoggedGetters.profile.budgetControl != 1"
                style="padding: 20px;"
                key="5"
            >
                <v-row>
                    <v-col 
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <v-data-table
                            :headers="headersConstructionItems"
                            :items="listProjectConstruction"
                            :options.sync="optionsConstructionItems"
                            :loading="loading"
                            :items-per-page="40"
                            no-data-text="No Record Found"
                        >
                            <template v-slot:item.statusDescription="{ item }">
                                <v-chip
                                    :color="getStatusConstructionColor(item.status)"
                                    outlined
                                    dark
                                    small
                                >
                                    {{ item.statusDescription }}
                                </v-chip>
                            </template>

                            <template v-slot:item.subContractorName="{ item }">
                                <span
                                    v-for="(itemSub, index) in item.subContractorName.toString().split(';')" :key=index
                                    outlined
                                    dark
                                    small
                                >
                                    {{ itemSub }}
                                    <br v-if="itemSub != ''" />
                                </span>
                            </template>

                            <template v-slot:item.detailsDescription="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            v-if="item.checkList == 1"
                                            color="var(--color__main)"
                                            outlined
                                            dark
                                            small
                                            v-on="on"
                                            style="margin-right: 5px;"
                                        >
                                            C
                                        </v-chip>
                                    </template>
                                    <span>Has Checklist</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            v-if="item.form == 1"
                                            color="var(--color__main)"
                                            outlined
                                            dark
                                            small
                                            v-on="on"
                                        >
                                            F
                                        </v-chip>
                                    </template>
                                    <span>Has Form</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:item.action="{ item }">
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="btnActions buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>

                                        <v-list-item
                                            v-for="(itemConstruction, index) in listConstructionActions.filter(act => act.idPanel == item.idPanel && act.idStage == item.idStage)" :key="index"
                                            @click="showDialogActivities(
                                                item.idServiceProject,                  //idServiceProject
                                                itemConstruction.idPanel,               //idPanel
                                                itemConstruction.idStage,               //idStage
                                                itemConstruction.id,                    //idAction
                                                itemConstruction.status,                //status
                                                itemConstruction.labelName,             //title
                                                false,                                  //showPanelStages
                                                false,                                  //readOnlyNotes
                                                item.status == 3                        //allReadOnly
                                            )"
                                        >
                                            <v-list-item-title>
                                                <v-icon left>{{ itemConstruction.icon }}</v-icon> {{ itemConstruction.labelName }} 

                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-chip
                                                                v-if="itemConstruction.checkList == 1"
                                                                color="var(--color__main)"
                                                                outlined
                                                                dark
                                                                x-small
                                                                v-on="on"
                                                                style="margin-left: 5px; margin-bottom: 5px; margin-right: 5px;"
                                                            >
                                                                C
                                                            </v-chip>
                                                        </template>
                                                        <span>Has Checklist</span>
                                                    </v-tooltip>

                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-chip
                                                                v-if="itemConstruction.form == 1"
                                                                color="var(--color__main)"
                                                                outlined
                                                                dark
                                                                x-small
                                                                v-on="on"
                                                                style="margin-bottom: 5px;"
                                                            >
                                                                F
                                                            </v-chip>
                                                        </template>
                                                        <span>Has Form</span>
                                                    </v-tooltip>
                                            </v-list-item-title>
                                        </v-list-item>

                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

            </v-tab-item>

            <!-- HISTORY -->
            <v-tab-item 
                style="padding: 20px;"
                key="6"
            >
                <br />
                <v-row>
                    <v-col 
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                    >
                        <label>Department</label>
                        <v-combobox
                            v-model="filterHistory.profileSelected"
                            :items="listProfile" 
                            item-text="name"
                            item-value="id"
                            clearable
                            outlined
                            dense
                            @change="filterHistoryRegisters()"
                        ></v-combobox>
                    </v-col>
                    
                    <v-col 
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                    >
                        <label>Quick Search</label>
                        <v-form @submit.prevent="getHistoryRegister">
                            <v-text-field
                                v-model="filterHistory.fastSearch"
                                prepend-inner-icon="mdi mdi-magnify"
                                outlined
                                single-line
                                @click:prepend-inner="getHistoryRegister"
                                dense
                            ></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col  
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <v-data-table
                            :headers="headersHistory"
                            :items="filteredHistorySearch"
                            :items-per-page="10"
                            no-data-text="No history registered"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-tab-item>

            <!-- DETAILS -->
            <v-tab-item 
                style="padding: 20px;"
                key="7"
            >
                <br />
                
                <v-row>
                    <v-col
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <label>Estimated Value - Zillow</label>
                        <br />
                        <label 
                            style="font-size: 30px !important; color: var(--color__main) !important"
                        >
                            {{ estimatedValueZillow }}
                        </label>
                        <ZillowTerms :address="address" :lgWidthTerms="'3'" :lgWidthLogo="'3'"  />
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col 
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                    >
                        <label>Estimated Start Date</label>
                        <v-text-field
                            prepend-inner-icon="mdi-calendar"
                            v-model="serviceRequest.estimatedDateFormatted" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                    >
                        <label>Estimated Buget</label>
                        <v-text-field
                            v-model="estimatedBudget" 
                            v-formatMoney="moneyFormat"
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col 
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <label>Notes</label>
                        <v-textarea
                            v-model="serviceRequest.notes"
                            readonly
                            single-line
                            rows="4"
                            row-height="30"
                            outlined
                        >
                        </v-textarea>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col 
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <ContentHeader :description="'Project Informations'" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>Type of Work</label>
                        <v-text-field
                            v-model="serviceProjectResponse.kindWorkDescription" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>Have a Survey?</label>
                        <v-text-field
                            v-model="serviceProjectResponse.surveyDescription" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>Address</label>
                        <v-text-field
                            v-model="serviceProjectResponse.addressSelected.address" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>City</label>
                        <v-text-field
                            v-model="serviceProjectResponse.addressSelected.cityDescription" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>ZipCode</label>
                        <v-text-field
                            v-model="serviceProjectResponse.addressSelected.zipCode" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>Is it a Gated Community?</label>
                        <v-text-field
                            v-model="serviceProjectResponse.addressSelected.isGatedCommunityDescription" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>Gated Community</label>
                        <v-text-field
                            v-model="serviceProjectResponse.addressSelected.gatedCommunity" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>Gated Community Details</label>
                        <v-text-field
                            v-model="serviceProjectResponse.addressSelected.communityDetails" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>Gate Code</label>
                        <v-text-field
                            v-model="serviceProjectResponse.addressSelected.gateCode" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                    >
                        <label>Dog On Site</label>
                        <v-text-field
                            v-model="serviceProjectResponse.addressSelected.dogOnSiteDescription" 
                            readonly
                            single-line
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col 
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <label>Notes</label>
                        <v-textarea
                            v-model="serviceProjectResponse.notes"
                            single-line
                            rows="4"
                            row-height="30"
                            outlined
                            dense
                        >
                        </v-textarea>
                    </v-col>
                </v-row>

            </v-tab-item>

            <!-- CALENDAR HISTORY -->
            <v-tab-item 
                style="padding: 20px;"
                key="8"
            >
                <br />
                <v-row>                    
                    <v-col 
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <label>Quick Search</label>
                        <v-form @submit.prevent="getCalendarHistoryRegister">
                            <v-text-field
                                v-model="filterCalendarHistory.fastSearch"
                                prepend-inner-icon="mdi mdi-magnify"
                                outlined
                                single-line
                                @click:prepend-inner="getCalendarHistoryRegister"
                                dense
                            ></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col  
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <v-data-table
                            :headers="headersCalendarHistory"
                            :items="filteredCalendarHistorySearch"
                            :items-per-page="10"
                            no-data-text="No history registered"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>

        <!-- Buttons: CANCEL | BACK -->
        <v-row>
            <v-col 
                cols="12"
            >
                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-tooltip 
                            v-if="userLoggedGetters.profile.systemAdmin == 1"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-if="userLoggedGetters.profile.systemAdmin == 1"
                                    class="mx-2"
                                    v-on="on"
                                    style="background-color: transparent !important; color: var(--color__red) !important; border: solid 1px;"
                                    @click="showDialogProject = true;"
                                    outlined
                                >
                                    Cancel Project
                                </v-btn>
                            </template>
                            <span>Cancel Project</span>
                        </v-tooltip>
                        
                        <v-tooltip 
                            v-if="tab != 4"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn 
                                    class="mx-2"
                                    v-on="on"
                                    style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                    @click="back()"
                                >
                                    Back
                                </v-btn>
                            </template>
                            <span>Back</span>
                        </v-tooltip>
                    </div>
                </v-card-actions>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showDialogProject"
            transition="dialog-top-transition"
            persistent
            width="800"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Select the projects to cancel
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Projects</label>
                            <v-combobox
                                v-model="listProjectsCancel"
                                :items="listProjects"
                                item-text="nameWithVersion"
                                item-value="id"
                                multiple
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 buttonCancelDialog"
                            style=""
                            outlined
                            @click="showDialogProject = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            class="mx-2"
                            outlined
                            @click="confirmCancelProject()"
                        >
                            Confirm
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
        
        <DialogActivities
            :idAux="dialogActivity.idAux"
            :idServiceProject="dialogActivity.idServiceProject"
            :idPanel="dialogActivity.idPanel"
            :idStage="dialogActivity.idStage"
            :idAction="dialogActivity.idAction"
            :status="dialogActivity.status"
            :title="dialogActivity.title"
            :showPanelStages="dialogActivity.showPanelStages"
            :readOnlyNotes="dialogActivity.readOnlyNotes"
            :allReadOnly="dialogActivity.allReadOnly"
            v-on:methodConfirmToCall="dialogActivity.methodConfirm"
            :showDialogActivities.sync="dialogActivity.show"
        />

        <ShowFiles 
            :showFiles.sync="dialogShowFiles.show"
            :showAsCarrousel.sync="dialogShowFiles.showAsCarrousel"
            :reload="dialogShowFiles.reload"
            :listParams="dialogShowFiles.listParams"
        />

        <DialogImportantMessage 
            v-on:methodConfirmToCall="dialogImportantMessage.methodConfirm"
            :showDialogImportantMessage.sync="dialogImportantMessage.show"
            :id="dialogImportantMessage.id"
            :message="dialogImportantMessage.message"
        />
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ZillowTerms from '@/components/Zillow/ZillowTerms';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import DialogActivities from "@/components/Services/DialogActivities";
    import JobInformation from "@/components/Services/JobInformation";
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";
    import TechnicalSpecification from "@/components/Services/TechnicalSpecification";
    import ShowFiles from "@/components/File/ShowFiles";
    import DialogImportantMessage from "@/components/Services/DialogImportantMessage";
    import { orderBy } from '@/utilities/Utils';
    import { 
        URL_ATTACHMENT, 
        URL_ATTACHMENT_LOAD_CALCULATION, 
        PROJECT_STATUS 
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionList,
            ActionDialog,
            JobInformation,
            ContentHeader,
            ZillowTerms,
            DialogActivities,
            TechnicalSpecification,
            ShowFiles,
            DialogImportantMessage
        },

        mixins: [Helpers],

        data: vm => ({

            URL_ATTACHMENT,
            URL_ATTACHMENT_LOAD_CALCULATION,

            validFormCustomer: true,
            validFormScheduling: true,
            tab: null,

            listProjects: [],
            projectSelected: null,
            refreshJob: false,

            estimatedValueZillow: "$ 0,00",
            estimatedBudget: "$ 0,00",

            address: null,
            specifyAppointmentLocation: true,

            listProfile: [],
            listModules: [],
            listStatus: [],
            listStatusFiltered: [],
            listProjectItems: [],
            listPermittings: [],
            listPermittingsAttachments: [],
            permittingAttachmentNOC: null,

            //NOTE
            validFormNote: true,
            listNote: [],
            showDialogNote: false,
            noteSearchModuleSelected: null,
            noteSearchStageSelected: null,
            noteModuleSelected: null,
            noteStageSelected: null,
            noteProductSelected: null,
            noteFilesPhotos: [],
            notePhotos: [],
            note: "",
            itemNote: null,

            filterNotes: {
                fastSearch: '',
            },

            classificationNotesSelected: null,
            listClassificationNotes: [],

            headersNote: [
                { text: "Date", value: "dateRegisterFormatted", sortable: true },
                { text: "Note", value: "description", sortable: true, width: "40%" },
                { text: "Department", value: "statusDescription", sortable: true },
                { text: "Product", value: "productDescription", sortable: true },
                { text: "Username", value: "userName", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            //HISTORY
            filterHistory: {
                profileSelected: null,
                fastSearch: '',
            },
            listHistory: [],
            listHistoryFiltered: [],
            headersHistory: [
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Department", value: "profileName", sortable: true },
                { text: "Subject", value: "description", sortable: true },
                { text: "Created By", value: "userName", sortable: true },
            ],

            //CALENDAR HISTORY
            filterCalendarHistory: {
                fastSearch: '',
            },
            listCalendarHistory: [],
            headersCalendarHistory: [
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
                { text: "Created By", value: "userNameRegister", sortable: true },
                { text: "Description", value: "description", sortable: true },
            ],

            serviceRequest: {
                id: 0,
                dateRegister: 0,
                idUserRegister: 0,
                idKindWork: 3,
                isSurvey: 1,
                isCustomer: 0,
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: "",
                    cityDescription: "",
                    zipCode: "",
                    isGatedCommunityDescription: "",
                    gatedCommunity: "",
                    communityDetails: "",
                    gateCode: "",
                    dogOnSiteDescription: "",
                },
                phone: "",
                email: "",
                notes: "",
                appointmentAddress: "",
                appointmentCitySelected: {
                    codeStateDescriptionCity: "",
                    stateSelected: {
                        code: ""
                    }
                },
                status: "",
            },

            serviceProjectResponse: {
                kindWorkDescription: '',
                surveyDescription: '',
                addressSelected: {
                    address: "",
                    cityDescription: "",
                    zipCode: "",
                    isGatedCommunityDescription: "",
                    gatedCommunity: "",
                    communityDetails: "",
                    gateCode: "",
                    dogOnSiteDescription: "",
                },
                status: 1,
                statusDescription: '',
                notes: "",
            },

            listCustomerFiles: [],
            listProposalFiles: [],
            listContractFiles: [],
            listHORespFiles: [],
            listAddendumFiles: [],
            listColorSelectionFiles: [],
            listLoadCalculationFiles: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogImportantMessage: {
                show: false,
                id: 0,
                message: "",
                methodConfirm: ( () => {} )
            },

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },
            AMPMStart: "AM",
			timeStartDisplay: null,
            AMPMEnd: "AM",
			timeEndDisplay: null,

            listProjectsCancel: null,
            showDialogProject: false,

            loading: false,

            listConstructionPanel: [],
            listConstructionStages: [],
            listConstructionStagesFiltered: [],
            listConstructionStagesSearchFiltered: [],
            listUser: [],
            userSelected: null,

            listProjectConstruction: [],

            headersConstructionItems: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Department", value: "panelDescription", sortable: true },
                { text: "Phase", value: "stageDescription", sortable: true },
                { text: "SubContractor", value: "subContractorName", sortable: true },
                { text: "Start Date", value: "startDateDescription", sortable: true },
                { text: "End Date", value: "endDateDescription", sortable: true },
                { text: "Details", value: "detailsDescription", sortable: true },
                { text: "Status", value: "statusDescription", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            listConstructionActions: [],

            optionsConstructionItems: { rowsPerPage: 40, page: 1 },

            dialogActivity: {
                idAux: 0,
                idServiceProject: 0,
                idPanel: 0,
                idStage: 0,
                idAction: 0,
                status: 0,
                title: "",
                show: false,
                showPanelStages: false,
                readOnlyNotes: false,
                allReadOnly: false,
                methodConfirm: ( () => {} ),
            },

            forceTSUpdate: true,

            showToCustomer: 0,
            today: (new Date(Date.now() - ( new Date() ).getTimezoneOffset() * 60000) ).toISOString().substr(0, 10),
            expirationDate: vm.today,
            expirationDateFormatted: vm.formatDate(vm.today),
            mnuExpirationDate: false,

            PROJECT_STATUS,
            
            dialogShowFiles: {
                show: false,
                showAsCarrousel: true,
                reload: 0,
                listParams: []
            },
            
            validations: {
                required: required
            }
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
            
            stateCode() {
                
                if (this.serviceRequest.appointmentStateSelected != null && this.serviceRequest.appointmentStateSelected != undefined) {
                    return this.serviceRequest.appointmentStateSelected.code
                }
                else {
                    return "";
                }
            },
            
            descriptionCity() {

                if (this.serviceRequest.appointmentCitySelected != null && this.serviceRequest.appointmentCitySelected != undefined) {
                    return this.serviceRequest.appointmentCitySelected.descriptionCity ?? this.serviceRequest.appointmentCitySelected.description;
                }
                else {
                    return "";
                }
            },
            
            googleMapsAddress() {
                let googleMapsAddress = this.serviceRequest.appointmentAddress;
                
                if (this.serviceRequest.appointmentStateSelected != null && this.serviceRequest.appointmentStateSelected != undefined) {
                    googleMapsAddress += `, ${this.serviceRequest.appointmentStateSelected.code}`;
                }

                if (this.serviceRequest.appointmentCitySelected != null && this.serviceRequest.appointmentCitySelected != undefined) {
                    googleMapsAddress += `, ${this.serviceRequest.appointmentCitySelected.descriptionCity ?? this.serviceRequest.appointmentCitySelected.description}`;
                }

                return googleMapsAddress;
            },

            filteredHistorySearch: function() {
                return this.listHistoryFiltered.filter((historyFilter) => {
                    
                    let filter = this.filterHistory.fastSearch.toLowerCase();

                    var dateRegisterFormatted = historyFilter.dateRegisterFormatted != null && historyFilter.dateRegisterFormatted != undefined ? historyFilter.dateRegisterFormatted.toLowerCase().match(filter) : ""
                    var profileName = historyFilter.profileName != null && historyFilter.profileName != undefined ? historyFilter.profileName.toLowerCase().match(filter) : ""
                    var description = historyFilter.description != null && historyFilter.description != undefined ? historyFilter.description.toLowerCase().match(filter): ""
                    var userName = historyFilter.userName != null && historyFilter.userName != undefined ? historyFilter.userName.toLowerCase().match(filter): ""
                    var id = historyFilter.id.toString().match(filter);

                    if (dateRegisterFormatted != null) { return dateRegisterFormatted; } 
                    else if(profileName != null) { return profileName; } 
                    else if(description != null) { return description; } 
                    else if(userName != null) { return userName; } 
                    else { return id; }
                });
            },

            filteredCalendarHistorySearch: function() {
                return this.listCalendarHistory.filter((historyFilter) => {
                    
                    const filter = this.filterCalendarHistory.fastSearch.toLowerCase();

                    const dateRegisterFormatted = historyFilter.dateRegisterFormatted != null && historyFilter.dateRegisterFormatted != undefined ? historyFilter.dateRegisterFormatted.toLowerCase().match(filter) : ""
                    const userNameRegister = historyFilter.userNameRegister != null && historyFilter.userNameRegister != undefined ? historyFilter.userNameRegister.toLowerCase().match(filter): ""
                    const description = historyFilter.description != null && historyFilter.description != undefined ? historyFilter.description.toLowerCase().match(filter): ""
                    const id = historyFilter.id.toString().match(filter);

                    if (dateRegisterFormatted != null) { return dateRegisterFormatted; } 
                    else if(userNameRegister != null) { return userNameRegister; } 
                    else if(description != null) { return description; } 
                    else { return id; }
                });
            },

            filteredNotesSearch: function() {
                return this.listNote.filter((notesFilter) => {
                    
                    let filter = this.filterNotes.fastSearch.toLowerCase();

                    var dateRegisterFormatted = notesFilter.dateRegisterFormatted != null && notesFilter.dateRegisterFormatted != undefined ? notesFilter.dateRegisterFormatted.toLowerCase().match(filter) : ""
                    var description = notesFilter.description != null && notesFilter.description != undefined ? notesFilter.description.toLowerCase().match(filter): ""
                    var profileName = notesFilter.profileName != null && notesFilter.profileName != undefined ? notesFilter.profileName.toLowerCase().match(filter) : ""
                    var userName = notesFilter.userName != null && notesFilter.userName != undefined ? notesFilter.userName.toLowerCase().match(filter): ""
                    var id = notesFilter.id.toString().match(filter);

                    if (dateRegisterFormatted != null) { return dateRegisterFormatted; } 
                    else if(profileName != null) { return profileName; } 
                    else if(description != null) { return description; } 
                    else if(userName != null) { return userName; } 
                    else { return id; }
                });
            },

            canCreateImportantMessage() {
                return this.userLoggedGetters.profile.createImportantMessage == 1;
            },

            showImportantMessage() {
                return this.serviceRequest.importantMessage != null && 
                       this.serviceRequest.importantMessage != undefined && 
                       this.serviceRequest.importantMessage.toString().trim() != ""
            },

            isProfileAdmin() {
                return (this.userLoggedGetters != null && 
                        this.userLoggedGetters.profile != null && 
                        this.userLoggedGetters.profile.systemAdmin != null && 
                        this.userLoggedGetters.profile.systemAdmin == 1) ||
                       (this.userLoggedGetters != null && this.userLoggedGetters.id == 0)
            },

            isSalesPerson() {
                return this.userLoggedGetters.profile.budgetControl == 1;
            },

            showEspecialDocuments() {
                return this.isProfileAdmin || this.isSalesPerson;
            }
        },

        watch: {

            expirationDate () {
                this.expirationDateFormatted = this.formatDate(this.expirationDate)
            }
        },

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            async back() {
                history.go(-1);
            },
			
            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 0:
                        color = 'var(--color__silver)';
                        break;

                    case 1:
                        color = 'var(--color__red)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                        color = 'var(--color__status_pending)';
                        break;

                    case 4:
                        color = 'var(--color__completed)';
                        break;

                    case 5:
                        color = 'var(--color__completed)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },
			
            getStatusConstructionColor (status) {

                let color = "";

                switch (status) {

                    case 1:
                        color = 'var(--color__status_pending)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                        color = 'var(--color__completed)';
                        break;

                    case 4:
                        color = 'var(--color__red)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },
            
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            async getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },

            async getStageStatus(itemStage) {

                let statusPending       = { id: 1, description: 'Pending' }
                let statusInProgress    = { id: 2, description: 'In Progress' }
                let statusCompleted     = { id: 3, description: 'Completed' }

                let status = statusPending;

                let projectStatus = this.serviceProjectResponse.status;

                if (itemStage.idStage == 1) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 2) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_LOCALES.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PREDIG_LOCALES.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 3) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE_RESP.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE_RESP.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 4) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PREDIG_REBAR.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PREDIG_REBAR.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 5) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_DIGDEMO.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_DIGDEMO.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 6) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_FORMSTEEL.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_FORMSTEEL.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 7) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_FRONTENDINSPECTION.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_FRONTENDINSPECTION.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 8) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_PREPLUMB.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_PREPLUMB.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 9) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_GUNITE.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_GUNITE.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 10) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_BACKFILL.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_BACKFILL.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 11) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 12) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.idINSPECTION) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.idINSPECTION) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 13) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PATIOGRADE.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PATIOGRADE.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 14) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 15) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 16) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 17) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSTALL.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSTALL.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 18) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_FINALGRADE.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_FINALGRADE.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 19) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_CLEANUP.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_CLEANUP.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 20) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSPECTION.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSPECTION.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 21) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 22) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_ELECTRICHOOKUP.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_ELECTRICHOOKUP.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 23) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_PREPLASTER.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_PREPLASTER.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 24) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 25) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_FINALWALKTHROUGHT.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PLASTE_FINALWALKTHROUGHT.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 26) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_VKBOWLDELIVERY.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PLASTE_VKBOWLDELIVERY.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 27) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_PREP.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PLASTE_PREP.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 28) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_PLASTER.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PLASTE_PLASTER.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 29) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 30) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_FIREUP.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_SERVICE_FIREUP.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 31) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_AFTERPLASTER.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_SERVICE_AFTERPLASTER.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 32) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_TURNOVER.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_SERVICE_TURNOVER.id) {
                        status = statusCompleted
                    }
                }

                if (itemStage.idStage == 33) {
                    if (projectStatus == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_INSPECTION.id) {
                        status = statusInProgress
                    }
                    else if (projectStatus > this.PROJECT_STATUS.CONSTRUCTION_SERVICE_INSPECTION.id) {
                        status = statusCompleted
                    }
                }

                return status;
            },

            async updateProject(projectSelected, listProjects) {

                this.showLoading();

                this.projectSelected = projectSelected;
                this.listProjects = listProjects;

                if (projectSelected != null && projectSelected != undefined) {
                    
                    const idServiceProject = await this.getIDProject();
                    
                    const response = await this.$store.dispatch("serviceModule/GetServiceProjectById", idServiceProject);
                    this.serviceProjectResponse = response.result;

                    this.getDocuments(idServiceProject);

                    this.getListNotes();
                    
                    await this.listConstructionStages.forEach(async itemStage => {

                        const status = await this.getStageStatus(itemStage);

                        const listActions = this.listConstructionActions.filter(act => act.idPanel == itemStage.idPanel && act.idStage == itemStage.idStage);

                        if (listActions != null && listActions != undefined) {

                            const hasForm = listActions.filter(act => act.form == 1).length > 0;
                            const hasCheckList = listActions.filter(act => act.checkList == 1).length > 0;
                            const hasSubContractor = listActions.filter(act => act.subContractor == 1).length > 0;

                            let subContractorName = '';

                            if (hasSubContractor == true) {

                                for (const itemAct of listActions) {
                                    const responseActionsRegisters = await this.$store.dispatch("actionsModule/GetRegisters", { idServiceProject, idAction: itemAct.id });

                                    if (responseActionsRegisters.success) {
                                        if (responseActionsRegisters.result.contractorSelected != null && responseActionsRegisters.result.contractorSelected != undefined) {
                                            subContractorName += `${responseActionsRegisters.result.contractorSelected.name};`;
                                        }
                                    }
                                }
                            }

                            this.listProjectConstruction.push({
                                id: itemStage.id,
                                idServiceProject,
                                idPanel: itemStage.idPanel,
                                panelDescription: itemStage.panelDescription,
                                idStage: itemStage.idStage,
                                stageDescription: itemStage.stageDescription,
                                subContractorName,
                                form: hasForm != null && hasForm != undefined && hasForm == true ? 1 : 0,
                                checkList: hasCheckList != null && hasCheckList != undefined && hasCheckList == true ? 1 : 0,
                                status: status.id,
                                statusDescription: status.description
                            });

                            this.listProjectConstruction = this.listProjectConstruction.sort((a, b) => { return orderBy(a, b, 0, 'id') });
                        }

                        this.$forceUpdate();
                    })

                    // setTimeout(() => {
                    //     this.listProjectConstruction = this.listProjectConstruction.sort((a, b) => { return orderBy(a, b, 0, 'id') });
                    // }, 2000);
                }

                this.hideLoading();
            },

            //NOTE
            async getListNotes() {
                const idServiceProject = await this.getIDProject();

                this.listProjectItems = await this.$store.dispatch("serviceModule/ListItems", idServiceProject);

                let idStatus = 0;
                let idUser = 0;

                if (this.noteSearchModuleSelected != null && this.noteSearchModuleSelected != undefined) {
                    idStatus = this.noteSearchModuleSelected.id;
                }

                if (this.userSelected != null && this.userSelected != undefined) {
                    idUser = this.userSelected.id;
                }

                this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                    idService: 0, 
                    idServiceProject, 
                    onlyCheckExists: false,
                    idStatus,
                    idPanel: 0,
                    idStage: 0,
                    idUser
                });

                let listProjectNoteReadRequest = [];

                this.listNote.forEach(item => {
                    listProjectNoteReadRequest.push({
                        idServiceProject,    
                        idNote: item.id,
                        idUser: this.userLoggedGetters.id,
                    })
                });

                this.$store.dispatch("serviceModule/CreateUpdateNoteRead", listProjectNoteReadRequest);
            },

            async clearFieldsNote() {

                this.noteModuleSelected = this.listModules[0];
                this.filterStages(true);
                this.note = "";
                this.showToCustomer = 0;
                this.expirationDate = this.today;
                this.expirationDateFormatted = this.formatDate(this.today);
                this.classificationNotesSelected = null;
                this.itemNote = null;
                this.notePhotos = [];
            },

            async showDialogNoteAndClearFields()  {

                this.clearFieldsNote();
                this.showDialogNote = true;
            },

            async showDialogNoteAndFillFields(id) {
                this.clearFieldsNote();
                this.itemNote = this.listNote.filter(not => not.id == id)[0];

                if (this.itemNote != null && this.itemNote != undefined) {

                    let moduleSelected = this.listModules.filter(mod => mod.id == this.itemNote.idModule)[0];
                    if (moduleSelected != null && moduleSelected != undefined) {
                        this.noteModuleSelected = moduleSelected;
                        this.filterStages(true);
                    }

                    let statusSelected = this.listStatusFiltered.filter(mod => mod.id == this.itemNote.idStatus)[0];
                    if (statusSelected != null && statusSelected != undefined) {
                        this.noteStageSelected = statusSelected;
                    }

                    let productSelected = this.listProjectItems.filter(mod => mod.idProduct == this.itemNote.idProduct)[0];
                    if (productSelected != null && productSelected != undefined) {
                        this.noteProductSelected = productSelected;
                    }
                    
                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
                        typeAttachment: TYPE_ATTACHMENT.PROJECT.value, 
                        typeDocument: TYPE_DOCUMENT.NOTE.value, 
                        idKey: id
                    });

                    listAttachments.forEach(itemPhoto => {

                        this.notePhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            type: itemPhoto.fileType,
                            fileName: itemPhoto.fileName,
                            fileType: itemPhoto.fileType,
                            createdOn: itemPhoto.dateRegisterFormatted,
                            userName: itemPhoto.userNameRegister,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        });
                    })
                    
                    this.note = this.itemNote.description;

                    this.showDialogNote = true;
                }
            },            

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async addNotesPhotos(idNote) {

                let allResult = []

                this.notePhotos.forEach(async itemPhoto => {

                    let result = await this.uploadFiles(itemPhoto.id, idNote, itemPhoto.file, TYPE_DOCUMENT.NOTE.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.NOTE.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload photos: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async saveNote() {

                await this.$refs.formNote.validate();

                if (this.validFormNote === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the notes form. Please review!");
                    return false;
                }
                else {
                    
                    let id = 0;
                    let noteRequest = null;
                    let idServiceProject = await this.getIDProject();
                    let idProfile = this.userLoggedGetters.profile.id;
                    let idModule = 0;
                    let idStatus = 0;
                    let idProduct = 0;
                    let idPanel = 0;
                    let idStage = 0;
                    let showToCustomer = 0;
                    let expirationDate = this.expirationDate;
                    let classification = null;

                    if (this.noteModuleSelected != null && this.noteModuleSelected != undefined) {
                        idModule = this.noteModuleSelected.id;
                    }

                    if (this.noteStageSelected != null && this.noteStageSelected != undefined) {
                        idStatus = this.noteStageSelected.id;
                    }

                    if (this.noteProductSelected != null && this.noteProductSelected != undefined) {
                        idProduct = this.noteProductSelected.idProduct;
                    }

                    if (this.classificationNotesSelected != null && this.classificationNotesSelected != undefined) {
                        classification = this.classificationNotesSelected.id;
                    }
                    
                    if (this.showToCustomer === null) {
                        this.showToCustomer = 0;
                    }

                    showToCustomer = this.showToCustomer;

                    if (this.itemNote != null) {
                        id = this.itemNote.id;
                    }

                    noteRequest = {
                        id,
                        idServiceProject,
                        idProfile,
                        idPanel,
                        idStage,
                        description: this.note.trim(),
                        showToCustomer,
                        expirationDate,
                        classification,
                        idStatus,
                        idModule,
                        idProduct
                    }

                    const result = await this.$store.dispatch("serviceModule/AddProjectNote", noteRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.addNotesPhotos(result.idNote);
                        this.getListNotes();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.showDialogNote = false;
                }
            },

            confirmNoteDelete(id) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will DELETE this note, confirm your decision?',
                    methodConfirm: this.noteDelete,
                    params: id
                };
            },

            async noteDelete(id) {

                this.showLoading();

                let result = await this.$store.dispatch("serviceModule/DeleteProjectNote", id);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getListNotes();

                this.hideLoading();
            },
            
            confirmPhotoDelete(itemPhotoToDelete) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this file [${itemPhotoToDelete.fileName}], confirm your decision?`,
                    methodConfirm: () => { itemPhotoToDelete.deleted = true; },
                    params: 0
                };
            },

            async showPhotos(id) {
                
                let listParams = [];
                let stageDescription = '';

                let note = this.listNote.filter (not => not.id == id)[0];

                if (note != null && note != undefined) {
                    stageDescription = note.statusDescription;
                }

                listParams.push({
                    stageDescription: stageDescription,
                    typeAttachment: TYPE_ATTACHMENT.PROJECT.value,
                    typeDocument: TYPE_DOCUMENT.NOTE.value,
                    typeAttachmentDescription: '',
                    typeDocumentDescription: '',
                    idKey: id,
                    isImage: true
                });

                this.dialogShowFiles = {
                    show: true,
                    showAsCarrousel: true,
                    reload: Math.random(),
                    listParams
                };
            },

            async getDocuments(idServiceProject) {

                const listAttachmentsCustomer = await this.$store.dispatch("attachmentModule/GetAllByDocument", { 
                    typeAttachment: TYPE_ATTACHMENT.CUSTOMER.value, 
                    typeDocument: TYPE_DOCUMENT.CUSTOMER_DOCUMENTS.value, 
                    idKey: this.serviceRequest.customerSelected.id
                });

                listAttachmentsCustomer.forEach(itemFile => {
                    this.listCustomerFiles.push({
                        id: itemFile.id,
                        fileName: itemFile.fileName,
                        createdOn: itemFile.dateRegisterFormatted,
                        userName: itemFile.userNameRegister
                    })
                })
                
                const listAttachmentsJobs = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.SERVICE.value, idKey: this.id });

                //SCOPE OF WORK
                const listFilesProposal = listAttachmentsJobs.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PROPOSAL.value);
                if (listFilesProposal != null && listFilesProposal != undefined) {

                    listFilesProposal.forEach(itemProposal => {
                        this.listProposalFiles.push ({
                            id: itemProposal.id,
                            fileName: itemProposal.fileName,
                            createdOn: itemProposal.dateRegisterFormatted,
                            userName: itemProposal.userNameRegister
                        });
                    });

                    this.listProposalFiles.sort((a, b) => { return orderBy(a, b, 1, 'id') });
                }

                //CONTRACT
                const listFilesContract = listAttachmentsJobs.filter(atc => atc.typeDocument == TYPE_DOCUMENT.CONTRACT.value);
                if (listFilesContract != null && listFilesContract != undefined) {

                    listFilesContract.forEach(itemContract => {
                        this.listContractFiles.push ({
                            id: itemContract.id,
                            fileName: itemContract.fileName,
                            createdOn: itemContract.dateRegisterFormatted,
                            userName: itemContract.userNameRegister
                        });
                    });

                    this.listContractFiles.sort((a, b) => { return orderBy(a, b, 1, 'id') });
                }

                //HOMEOWNER RESPONSABILITY
                const listFilesHOResp = listAttachmentsJobs.filter(atc => atc.typeDocument == TYPE_DOCUMENT.HOMEOWNER_RESPONSABILITY.value);
                if (listFilesHOResp != null && listFilesHOResp != undefined) {

                    listFilesHOResp.forEach(itemHOResp => {
                        this.listHORespFiles.push ({
                            id: itemHOResp.id,
                            fileName: itemHOResp.fileName,
                            createdOn: itemHOResp.dateRegisterFormatted,
                            userName: itemHOResp.userNameRegister
                        });
                    });

                    this.listHORespFiles.sort((a, b) => { return orderBy(a, b, 1, 'id') });
                }

                //ADDENDUM
                const listAttachmentsAddendum = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.SERVICE.value, idKey: idServiceProject });
                const listFilesAddendum = listAttachmentsAddendum.filter(atc => atc.typeDocument == TYPE_DOCUMENT.ADDENDUM.value);
                if (listFilesAddendum != null && listFilesAddendum != undefined) {

                    listFilesAddendum.forEach(itemAddendum => {
                        this.listAddendumFiles.push({
                            id: itemAddendum.id,
                            fileName: itemAddendum.fileName,
                            createdOn: itemAddendum.dateRegisterFormatted,
                            userName: itemAddendum.userNameRegister
                        });
                    });

                    this.listAddendumFiles.sort((a, b) => { return orderBy(a, b, 1, 'id') });
                }
                

                //COLOR SELECTION
                const listAttachmentsColorSelection = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.COLOR_SELECTION.value, idKey: idServiceProject });
                const listFilesColorSelection = listAttachmentsColorSelection.filter(atc => atc.typeDocument == TYPE_DOCUMENT.COLOR_SELECTION.value);
                if (listFilesColorSelection != null && listFilesColorSelection != undefined) {

                    listFilesColorSelection.forEach(itemAddendum => {
                        this.listColorSelectionFiles.push({
                            id: itemAddendum.id,
                            fileName: itemAddendum.fileName,
                            createdOn: itemAddendum.dateRegisterFormatted,
                            userName: itemAddendum.userNameRegister
                        });
                    });

                    this.listColorSelectionFiles.sort((a, b) => { return orderBy(a, b, 1, 'id') });
                }
                
                //LOAD CALCULATION
                const responseLoadCalculation = await this.$store.dispatch("serviceModule/GetLoadCalculationById", { id: this.id, idAddress: 0 } );

                if (responseLoadCalculation != null && responseLoadCalculation != undefined && responseLoadCalculation.result.id != 0) {

                    this.listLoadCalculationFiles.push({
                        id: this.id,
                        fileName: "LoadCalculation.pdf",
                        createdOn: responseLoadCalculation.result.dateRegisterFormatted,
                        userName: responseLoadCalculation.result.nameUserRegister
                    });
                }
            },

            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("serviceModule/GetById", this.id);

                    this.serviceRequest = response.result;

                    const addressSelected = {...this.serviceRequest.addressSelected}
                    
                    this.serviceRequest.addressSelected = addressSelected

                    this.estimatedValueZillow = this.serviceRequest.estimatedValueZillowFormatted;
                    this.estimatedBudget = this.serviceRequest.estimatedBudgetFormatted;

                    this.timeStartDisplay = this.serviceRequest.timeStartFormatted;
                    this.AMPMStart = this.serviceRequest.ampmTimeStart;
                    this.timeEndDisplay = this.serviceRequest.timeCloseFormatted;
                    this.AMPMEnd = this.serviceRequest.ampmTimeEnd;

                    this.getEstimatedValueZillow();

                    if (this.serviceRequest.isCustomer === 1) {
                        this.address = this.serviceRequest.addressSelected.address;
                    }
                    else {
                        this.address = this.serviceRequest.address;
                    }

                    let appointmentCityId = 0;
                    let idCity = 0;

                    if (this.serviceRequest.appointmentCitySelected != null && this.serviceRequest.appointmentCitySelected != undefined)
                        appointmentCityId = this.serviceRequest.appointmentCitySelected.id;

                    if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined)
                        idCity = this.serviceRequest.addressSelected.idCity;

                    this.specifyAppointmentLocation = this.serviceRequest.appointmentAddress != this.address || appointmentCityId != idCity;
                }
            },

            async getHistoryRegister() {
                this.listHistory = await this.$store.dispatch("serviceModule/ListHistory", this.id);
                this.filterHistoryRegisters()
            },

            async getCalendarHistoryRegister() {
                
                const scheduleListHistoryRequest = {
                    idService: this.id,
                    idServiceProject: 0,
                    idCustomer: 0
                }

                this.listCalendarHistory = await this.$store.dispatch("scheduleModule/ListHistory", scheduleListHistoryRequest);
            },

            async filterHistoryRegisters() {

                let idProfile = 0;
                let listFiltered = [...this.listHistory];

                if (this.filterHistory.profileSelected != null && this.filterHistory.profileSelected != undefined) {
                    idProfile = this.filterHistory.profileSelected.id;
                    listFiltered = listFiltered.filter(his => his.idProfile == idProfile);
                }

                this.listHistoryFiltered = listFiltered;
            },

            async getLists() {
                
                this.getHistoryRegister();
                this.listConstructionActions = await this.$store.dispatch("actionsModule/List");
                this.listProfile = await this.$store.dispatch("profileModule/List");
                this.listModules = await this.$store.dispatch("moduleModule/List");

                let listStatus = await this.$store.dispatch("serviceModule/ListStatus");
                this.listStatus = listStatus.filter(sts => sts.id >= 3).sort((a, b) => { return orderBy(a, b, 0, 'id') });

                this.listUser = await this.$store.dispatch("userModule/List");

                if (this.userLoggedGetters.profile.constructionUser == 1 || this.userLoggedGetters.profile.systemAdmin == 1) {
                    
                    this.headersNote = [
                        { text: "Date", value: "dateRegisterFormatted", sortable: true },
                        { text: "Note", value: "description", sortable: true, width: "40%" },
                        { text: "Department", value: "statusDescription", sortable: true },
                        { text: "Product", value: "productDescription", sortable: true },
                        { text: "Username", value: "userName", sortable: true },
                        { text: "Actions", value: "action", sortable: false, align: "center" }
                    ];
                }
                this.listConstructionPanel = await this.$store.dispatch("serviceModule/ListConstructionPanel");
                this.listConstructionStages = await this.$store.dispatch("serviceModule/ListConstructionStages");
                this.listClassificationNotes = await this.$store.dispatch("serviceModule/ListClassification");

                this.filterStages(false);
            },

            async getEstimatedValueZillow() {

                let zillowRequest = {
                    typeRequest: 2, //Address
                    zipCodeOrAddress: "",
                }

                if (this.serviceRequest.isCustomer === 1) {
                    if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined) {
                        zillowRequest.zipCodeOrAddress = this.serviceRequest.addressSelected.address;
                        this.address = this.serviceRequest.addressSelected.address;
                    }
                }
                else {
                    zillowRequest.zipCodeOrAddress = this.serviceRequest.address;
                    this.address = this.serviceRequest.address;
                }

                let response = await this.$vanKirkApi.post(`common/zillow`, zillowRequest, {
                        headers: {
                            "content-type": "application/json"
                        }
                    });
                let resultZillow = response.data.result;

                this.estimatedValueZillow = "$ 0,00";
                    
                if (resultZillow && resultZillow.success == true && resultZillow.bundle.length > 0) {
                    this.estimatedValueZillow = resultZillow.bundle[0].zEstimateFormatted;
                }
                else {
                    this.estimatedValueZillow = "Not found"
                }
            },

            async confirmCancelProject() {

                if (this.listProjectsCancel == null || this.listProjectsCancel == undefined || this.listProjectsCancel.length <= 0) {
                    this.showToast("error", "Error!", "Please select at least one project.");
                }
                else {
                
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: 'You will CANCEL all this projects, confirm your decision?',
                        methodConfirm: this.cancelProjects,
                        params: 0
                    };
                }
            },

            async cancelProjects() {
                this.listProjectsCancel.forEach(itemProject => {
                    if (itemProject) {

                        const updateSituationRequest = {
                            id: itemProject.id,
                            situation: 3, //Canceled
                        };

                        this.$store.dispatch("serviceModule/UpdateProjectSituation", updateSituationRequest);
                    }
                })

                const updateSituationRequest = {
                    id: this.id,
                    situation: 3, //Canceled
                };

                this.$store.dispatch("serviceModule/UpdateSituation", updateSituationRequest);

                this.showToast("success", "Success!", "Canceled with successfully!");

                this.back();
            },

            async showDialogActivities(
                idServiceProject, 
                idPanel, 
                idStage, 
                idAction, 
                status, 
                title, 
                showPanelStages, 
                readOnlyNotes,
                allReadOnly
            ) {                
                this.dialogActivity = {
                    idAux: Math.random(),
                    idServiceProject,
                    idPanel: idPanel,
                    idStage: idStage,
                    idAction: idAction,
                    status: status,
                    title: title,
                    show: true,
                    showPanelStages,
                    readOnlyNotes,
                    allReadOnly,
                    methodConfirm: ( () => { 
                        this.getRegister();
                    })
                }
            },

            async updateTechSpec() {

                setTimeout( () => {
                    this.forceTSUpdate = !this.forceTSUpdate;
                }, 50 )
            },

            filterStages(modalShowed) {
                let idModule = 0;

                if (modalShowed == true && this.noteModuleSelected != null && this.noteModuleSelected != undefined) {
                    idModule = this.noteModuleSelected.id;
                }
                else if (this.noteSearchModuleSelected != null && this.noteSearchModuleSelected != undefined) {
                    idModule = this.noteSearchModuleSelected.id;
                }

                if (idModule == 1) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 3 && sta.id <= 7)]
                }
                else if (idModule == 2) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 8 && sta.id <= 13)]
                }
                else if (idModule == 3) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 14 && sta.id <= 16)]
                }
                else if (idModule == 4) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 17 && sta.id <= 46)]
                }
                else if (idModule == 5) {
                    this.listStatusFiltered = [...this.listStatus.filter(sta => sta.id >= 47)]
                }
            },

            async goToPipeline() {

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.SALES_IN_PROGRESS &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.SALES_CONTRACTED) {
                    this.$router.push({ path: "/sales/pipeline" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.DRAFTING_REVIEW &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.DRAFTING_NEEDS_APPROVAL) {
                    this.$router.push({ path: "/drafting/pipeline" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.PERMITTING_INCOMING_PROJECTS &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.PERMITTING_FILE_REVIEW) {
                    this.$router.push({ path: "/permitting/pipeline" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_PREDIG_REBAR.id) {
                    this.$router.push({ path: "/construction/pipelinePreDig" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_DIGDEMO.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_BACKFILL.id) {
                    this.$router.push({ path: "/construction/pipelineFrontEnd" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION.id) {
                    this.$router.push({ path: "/construction/pipelineGradingPlumbing" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSPECTION.id) {
                    this.$router.push({ path: "/construction/pipelineTileDeck" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION.id) {
                    this.$router.push({ path: "/construction/pipelineEquipmentElectric" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_PLASTE_FINALWALKTHROUGHT.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id) {
                    this.$router.push({ path: "/construction/pipelinePrepPlaster" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.id) {
                    this.$router.push({ path: "/construction/pipelineBBQ" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FOOTERFRAME.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FINALINSPECTION.id) {
                    this.$router.push({ path: "/construction/pipelinePergola" });
                }

                if (this.serviceProjectResponse.status >= this.PROJECT_STATUS.CONSTRUCTION_SERVICE_FIREUP.id &&
                    this.serviceProjectResponse.status <= this.PROJECT_STATUS.CONSTRUCTION_SERVICE_INSPECTION.id) {
                    this.$router.push({ path: "/service/pipeline" });
                }
            },

            getResponsible(itemPermitting, itemResponsible) {

                let responsible = itemResponsible.responsibleDescription;

                if (itemResponsible.id == 3) {
                    if (itemPermitting.nameContractor != null && itemPermitting.nameContractor != undefined && itemPermitting.nameContractor != '') {
                        responsible += ` - ${itemPermitting.nameContractor}`;
                    }
                }

                return responsible;
            },

            async getListPermittings() {
                const idServiceProject = await this.getIDProject();
                const responsePermitting = await this.$store.dispatch("serviceModule/GetPermittingById", idServiceProject);
                
                this.listPermittings = responsePermitting.result.filter(per => per.apply == 1);
                this.listPermittingsAttachments = [];
                const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: idServiceProject });
                
                //DOCUMENTS
                const nocAttachment = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_NOC.value);  

                if (nocAttachment != null && nocAttachment != undefined && nocAttachment.length > 0) {
                    this.permittingAttachmentNOC = {
                        type: 'NOC',
                        id: nocAttachment[0].id,
                        fileName: nocAttachment[0].fileName,
                        fileType: nocAttachment[0].fileType
                    };
                }

                this.listPermittings.forEach(itemPermitting => {
                    
                    this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: itemPermitting.idPermitting }).then (response => {

                        if (response != null && response != undefined) {
                            response.forEach(itemFile => {
                    
                                if (itemFile.typeDocument == TYPE_DOCUMENT.PERMITTING_ONGOING_FILE.value) {

                                    this.listPermittingsAttachments.push({
                                        type: 'PER',
                                        idPermitting: itemPermitting.idPermitting,
                                        id: itemFile.id,
                                        fileName: itemFile.fileName,
                                        fileType: itemFile.fileType
                                    })
                                }

                                this.$forceUpdate();
                            })
                        }
                    });
                });
            },

            showDialogImportantMessage() {
                this.dialogImportantMessage = {
                    id: this.serviceRequest.id,
                    message: this.serviceRequest.importantMessage,
                    show: true,
                    methodConfirm: ( async () => {
                        console.log('chamou');
                        await this.getRegister();
                    })
                }
            },

            async deleteImportantMessage() {
                
                const serviceImportantMessageRequest = {
                    importantMessage: null
                }

                const result = await this.$store.dispatch("serviceModule/SetImportantMessage", { idService: this.id, serviceImportantMessageRequest });

                if (result.success === true) {
                    await this.getRegister();
                    this.showToast("success", "Success!", "Message deleted successfully!");
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style>
    .tabVertical > div > .v-slide-group__wrapper {
        margin-top: 15px !important;
        border-right: solid 1px #ddd !important;
    }
</style>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    tbody > tr:hover {
        background-color: transparent !important;
    }

    .selectedRow, .selectedRow:hover {
        background-color: var(--color__main) !important;
        color: #fff;
        font-weight: bold;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

    .cardDownload {
        text-align: center;
    }

    .cardTitleDownload {
        display: block !important;
        font-size: 15px;
        margin-bottom: 10px;
    }

    .cardTitlePhotosDownload {
        display: block !important;
    }

    .cardImagePhotosDownload {
        margin-bottom: 10px;
    }

    .btnActions {
        height: 30px !important;
        width: inherit !important;
    }

    .v-dialog--active {
        overflow: hidden !important;
    }

</style>