<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Profile</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="profileRequest.status"
                        label="Active"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-radio-group 
                        v-model="profileType" 
                        row
                        :rules=[validations.required]
                    >
                        <v-radio
                            label="System Admin"
                            :value="1"
                            color="var(--color__main)"
                        ></v-radio>
                        <v-radio
                            label="System User"
                            :value="2"
                            color="var(--color__main)"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row style="height: 100px">
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Name</label>
                    <v-text-field
                        v-model="profileRequest.name" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>

                <v-col cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Default Page</label>
                    <v-combobox
                        v-model="profileRequest.menuDefaultSelected"
                        :items="listMenuAdded"
                        item-text="friendlyName"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Permissions'" />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.validatePasswordChange"
                        label="Validate Password Change?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.budgetControl"
                        label="Budget Control?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.drafting"
                        label="Drafting?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.permitting"
                        label="Permitting?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="profileRequest.viewAddAllScheduleService"
                        label="View/Edit All Scheduled Jobs?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="profileRequest.viewAllSalesPersonPipeline"
                        label="View All Sales Person Pipeline?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.approvesProposal"
                        label="Approves Proposal?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.readOnlyDrafting"
                        label="Read Only in Drafting Department?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.returnCard"
                        label="Can return card?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.aproveInspectionWithoutRevision"
                        label="Approve Inspection Without Revision?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.reviewAllUnclosedDeals"
                        label="Review All Unclosed Deals?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.inputClientConsiderationsUnclosedDeals"
                        label="Input Client Considerations Unclosed Deals?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.rollbackWorkOrderIncomplete"
                        label="Rollback of Work Order Incomplete Status?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.editColorSelectionAfterPermitting"
                        label="Can edit color selection after permitting?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.createImportantMessage"
                        label="Can create/edit important messages for jobs?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.viewSchedulerOtherUsers"
                        label="Can view other users schedules?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.viewAllCustomers"
                        label="Can view all customers?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.editJobClassification"
                        label="Can edit job classification?"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Construction Permissions'" />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.constructionSupervisor"
                        label="Construction Supervisor?"
                        :value=1
                        color="var(--color__main)"
                        @change="checkAllConstructionPermission()"
                        dense
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="profileRequest.assignSupervisor"
                        label="Assign Supervisor?"
                        :value=1
                        color="var(--color__main)"
                        dense
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <label>Pre-Dig</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionPreDig"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionPreDig = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionPreDig = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionPreDig = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>

                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <label>Front End</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionFrontEnd"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionFrontEnd = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionFrontEnd = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionFrontEnd = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>

                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <label>Grading Plumbing</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionGradingPlumbing"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionGradingPlumbing = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionGradingPlumbing = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionGradingPlumbing = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>

                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <label>Tile / Deck</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionTileDeck"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionTileDeck = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionTileDeck = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionTileDeck = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>

                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <label>Equipment / Electric</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionEquipmentElectric"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionEquipmentElectric = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionEquipmentElectric = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionEquipmentElectric = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>

                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <label>Prep / Plaster</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionPrepPlaster"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionPrepPlaster = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionPrepPlaster = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionPrepPlaster = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>

                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <label>BBQ</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionBBQ"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionBBQ = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionBBQ = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionBBQ = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>

                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <label>Fence</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionFence"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionFence = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionFence = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionFence = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>

                <v-col 
                    class="marginPadding"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <label>Pergola</label>
                    <br />
                    <v-btn-toggle
                        v-model="profileRequest.constructionPergola"
                        dense
                    >
                        <v-btn 
                            @click="profileRequest.constructionPergola = 0" 
                            class="btnToogleNoAccess"
                        >
                            No Access
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionPergola = 1" 
                            class="btnToogleReadOnly"
                        >
                            Read-only
                        </v-btn>

                        <v-btn 
                            @click="profileRequest.constructionPergola = 2" 
                            class="btnToogle"
                        >
                            Full Access
                        </v-btn>

                    </v-btn-toggle>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    style="margin-top: 20px;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Menu Access'" />
                </v-col>
            </v-row>
            <v-row v-for="(item, index) in profileRequest.listProfileMenu" :key="index" style="height: 60px;" >
                <v-col 
                    cols="10"
                    lg="11"
                    md="10"
                    sm="10"
                >
                    <v-combobox
                        v-model="item.menuSelected"
                        :items="listMenu"
                        item-text="friendlyName"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="2"
                    lg="1"
                    md="2"
                    sm="2"
                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                >
                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="btnActions"
                                outlined
                                fab
                                x-small
                                v-on="on"
                                @click="addNewItem(item)" 
                                color="blue" 
                                small
                                :key="index"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Add New Item</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="btnActions"
                                outlined
                                fab
                                x-small
                                v-on="on"
                                @click="deleteItem(index)" 
                                color="red" 
                                small
                                :disabled="profileRequest.listProfileMenu.length <= 1"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Delete Item</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="profileRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ContentHeader,
            ActionButtons
        },

        data: () => ({

            validForm: true,

            profileRequest: {
                id: 0,
                name: "",
                systemAdmin: 0,
                userSystem: 0,
                validatePasswordChange: 1,
                budgetControl: 0,
                drafting: 0,
                permitting: 0,
                viewAddAllScheduleService: 0,
                viewAllSalesPersonPipeline: 0,
                approvesProposal: 0,
                readOnlyDrafting: 0,
                constructionSupervisor: 0,
                assignSupervisor: 0,
                constructionPreDig: 0,
                constructionFrontEnd: 0,
                constructionGradingPlumbing: 0,
                constructionTileDeck: 0,
                constructionEquipmentElectric: 0,
                constructionPrepPlaster: 0,
                constructionBBQ: 0,
                constructionFence: 0,
                constructionPergola: 0,
                returnCard: 0,
                aproveInspectionWithoutRevision: 0,
                reviewAllUnclosedDeals: 0,
                inputClientConsiderationsUnclosedDeals: 0,
                rollbackWorkOrderIncomplete: 0,
                editColorSelectionAfterPermitting: 0,
                createImportantMessage: 0,
                viewSchedulerOtherUsers: 0,
                viewAllCustomers: 0,
                editJobClassification: 0,
                menuDefaultSelected: {
                    id: 0,
                    friendlyName: ""
                },
                listProfileMenu: []
            },

            profileType: 2,

            listMenu: [],
            listMenuAdded: [],

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async deleteItem(index) {
                this.profileRequest.listProfileMenu.splice(index, 1);
                
                let lastIndex = this.profileRequest.listProfileMenu.length - 1;

                this.profileRequest.listProfileMenu[lastIndex].showAddNewItem = true;
            },

            async addNewItem(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                this.profileRequest.listProfileMenu.push({
                    menuSelected: null,
                    showAddNewItem: true
                })

                this.addMenuAdded()
            },

            async addMenuAdded() {

                this.listMenuAdded = []
                this.profileRequest.listProfileMenu.forEach(itemProfileMenu => {
                    
                    if (itemProfileMenu.menuSelected != null && itemProfileMenu.menuSelected != undefined) {
                        this.listMenuAdded.push({
                            id: itemProfileMenu.menuSelected.id,
                            friendlyName: itemProfileMenu.menuSelected.friendlyName
                        })
                    }
                })
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("profileModule/GetById", this.id);

                    if (response.success) {
                        this.profileRequest = response.result;

                        if (this.profileRequest.systemAdmin == 1) {
                            this.profileType = 1
                        }
                        else {
                            this.profileType = 2
                        }

                        if (this.profileRequest.listProfileMenu.length == 0) {
                            this.addNewItem();
                        }
                        else {
                            this.addMenuAdded()
                        }
                    }

                }
                else {
                    this.profileRequest.status = 1;
                    this.addNewItem();
                }
            },

            async getLists() {
                
                this.listMenu = await this.$store.dispatch("menuModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/admin/profileList" });
            },

            async validateProfileMenu() {

                let valid = true;

                if (this.profileRequest.listProfileMenu !== null && this.profileRequest.listProfileMenu !== undefined) {

                    let listMenu = [...this.profileRequest.listProfileMenu];
                    let newListMenu = [];

                    this.profileRequest.listProfileMenu.forEach(itemProfileMenu => {
                        
                        if (itemProfileMenu.menuSelected != null && itemProfileMenu.menuSelected != undefined) {
                            
                            let existItem = listMenu.filter(ems => ems.menuSelected.id === itemProfileMenu.menuSelected.id);

                            if (existItem.length > 1 && valid) {
                                this.showToast("warning", "Warning!", `Menu [${itemProfileMenu.menuSelected.description}] is duplicated!`);
                                valid = false;
                            }
                            else {
                                newListMenu.push(itemProfileMenu);
                            }
                        }
                    });

                    if (valid) {
                        this.profileRequest.listProfileMenu = newListMenu;
                    }
                }

                return valid;
            },

            async checkAllConstructionPermission() {
                if (this.profileRequest.constructionSupervisor == 1) {
                    this.profileRequest.constructionPreDig = 2;
                    this.profileRequest.constructionFrontEnd = 2;
                    this.profileRequest.constructionGradingPlumbing = 2;
                    this.profileRequest.constructionTileDeck = 2;
                    this.profileRequest.constructionEquipmentElectric = 2;
                    this.profileRequest.constructionPrepPlaster = 2;
                    this.profileRequest.constructionBBQ = 2;
                    this.profileRequest.constructionFence = 2;
                    this.profileRequest.constructionPergola = 2;
                }
                else {
                    this.profileRequest.constructionPreDig = 0;
                    this.profileRequest.constructionFrontEnd = 0;
                    this.profileRequest.constructionGradingPlumbing = 0;
                    this.profileRequest.constructionTileDeck = 0;
                    this.profileRequest.constructionEquipmentElectric = 0;
                    this.profileRequest.constructionPrepPlaster = 0;
                    this.profileRequest.constructionBBQ = 0;
                    this.profileRequest.constructionFence = 0;
                    this.profileRequest.constructionPergola = 0;
                }
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {

                    if (await this.validateProfileMenu()) {
                        this.loading = true;
                        this.showLoading();

                        if (this.profileRequest.systemAdmin === null) {
                            this.profileRequest.systemAdmin = 0;
                        }

                        if (this.profileRequest.userSystem === null) {
                            this.profileRequest.userSystem = 0;
                        }

                        if (this.profileRequest.validatePasswordChange === null) {
                            this.profileRequest.validatePasswordChange = 0;
                        }

                        if (this.profileRequest.budgetControl === null) {
                            this.profileRequest.budgetControl = 0;
                        }

                        if (this.profileRequest.drafting === null) {
                            this.profileRequest.drafting = 0;
                        }

                        if (this.profileRequest.permitting === null) {
                            this.profileRequest.permitting = 0;
                        }

                        if (this.profileRequest.constructionSupervisor === null) {
                            this.profileRequest.constructionSupervisor = 0;
                        }

                        if (this.profileRequest.assignSupervisor === null) {
                            this.profileRequest.assignSupervisor = 0;
                        }

                        if (this.profileRequest.constructionPreDig === null) {
                            this.profileRequest.constructionPreDig = 0;
                        }

                        if (this.profileRequest.constructionFrontEnd === null) {
                            this.profileRequest.constructionFrontEnd = 0;
                        }

                        if (this.profileRequest.constructionGradingPlumbing === null) {
                            this.profileRequest.constructionGradingPlumbing = 0;
                        }

                        if (this.profileRequest.constructionTileDeck === null) {
                            this.profileRequest.constructionTileDeck = 0;
                        }

                        if (this.profileRequest.constructionEquipmentElectric === null) {
                            this.profileRequest.constructionEquipmentElectric = 0;
                        }

                        if (this.profileRequest.constructionPrepPlaster === null) {
                            this.profileRequest.constructionPrepPlaster = 0;
                        }

                        if (this.profileRequest.constructionBBQ === null) {
                            this.profileRequest.constructionBBQ = 0;
                        }

                        if (this.profileRequest.constructionFence === null) {
                            this.profileRequest.constructionFence = 0;
                        }

                        if (this.profileRequest.constructionPergola === null) {
                            this.profileRequest.constructionPergola = 0;
                        }

                        if (this.profileRequest.status === null) {
                            this.profileRequest.status = 0;
                        }

                        if (this.profileRequest.viewAddAllScheduleService === null) {
                            this.profileRequest.viewAddAllScheduleService = 0;
                        }

                        if (this.profileRequest.viewAllSalesPersonPipeline === null) {
                            this.profileRequest.viewAllSalesPersonPipeline = 0;
                        }

                        if (this.profileRequest.approvesProposal === null) {
                            this.profileRequest.approvesProposal = 0;
                        }

                        if (this.profileRequest.readOnlyDrafting === null) {
                            this.profileRequest.readOnlyDrafting = 0;
                        }

                        if (this.profileRequest.returnCard === null) {
                            this.profileRequest.returnCard = 0;
                        }

                        if (this.profileRequest.aproveInspectionWithoutRevision === null) {
                            this.profileRequest.aproveInspectionWithoutRevision = 0;
                        }

                        if (this.profileRequest.reviewAllUnclosedDeals === null) {
                            this.profileRequest.reviewAllUnclosedDeals = 0;
                        }

                        if (this.profileRequest.inputClientConsiderationsUnclosedDeals === null) {
                            this.profileRequest.inputClientConsiderationsUnclosedDeals = 0;
                        }

                        if (this.profileRequest.rollbackWorkOrderIncomplete === null) {
                            this.profileRequest.rollbackWorkOrderIncomplete = 0;
                        }

                        if (this.profileRequest.editColorSelectionAfterPermitting === null) {
                            this.profileRequest.editColorSelectionAfterPermitting = 0;
                        }

                        if (this.profileRequest.createImportantMessage === null) {
                            this.profileRequest.createImportantMessage = 0;
                        }

                        if (this.profileRequest.viewSchedulerOtherUsers === null) {
                            this.profileRequest.viewSchedulerOtherUsers = 0;
                        }

                        if (this.profileRequest.viewAllCustomers === null) {
                            this.profileRequest.viewAllCustomers = 0;
                        }

                        if (this.profileRequest.editJobClassification === null) {
                            this.profileRequest.editJobClassification = 0;
                        }

                        if (this.profileType == 1) {
                            this.profileRequest.systemAdmin = 1;
                            this.profileRequest.userSystem = 0;
                        }
                        else {
                            this.profileRequest.systemAdmin = 0;
                            this.profileRequest.userSystem = 1;
                        }

                        if (this.profileRequest.menuDefaultSelected != null && this.profileRequest.menuDefaultSelected != undefined) {
                            this.profileRequest.idMenuDefault = this.profileRequest.menuDefaultSelected.id
                        }
                        else {
                            this.profileRequest.idMenuDefault = 0
                        }

                        this.profileRequest.id = this.id;

                        const result = await this.$store.dispatch("profileModule/CreateUpdate", this.profileRequest);

                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.$router.push({ path: "/admin/profileList" });
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    div[class^='col-'] {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .colButtonList {
        padding-top: 12px;    
    }

    .colButtonList2 {
        margin-top: inherit;
    }
    
    .btnToogleNoAccess {
        background-color: transparent !important; 
        color: var(--color__red) !important;
    }
    
    .btnToogleReadOnly {
        background-color: transparent !important; 
        color: var(--color__cinza_escuro) !important;
    }

    .marginPadding {
        padding: 10px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>