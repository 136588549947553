<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="2"
                md="2"
                sm="12"
            >
                <h3>Pipeline - Drafting</h3>
            </v-col>
            <v-col 
                style="padding-top: 10px; display: flex;"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-combobox
                    v-model="sortSelected"
                    :items="listSortOptions"
                    label="Sort By"
                    item-text="description"
                    item-value="id"
                    clearable
                    outlined
                    @change="sortRegisters"
                    dense
                ></v-combobox>

                <v-tooltip bottom v-if="sortType == 0">
                    <template v-slot:activator="{ on }" v-if="sortType == 0">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 0" @click="sortType = 1; sortRegisters()">
                            <v-icon>mdi-sort-ascending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by ascending</span>
                </v-tooltip>

                <v-tooltip bottom v-if="sortType == 1">
                    <template v-slot:activator="{ on }" v-if="sortType == 1">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 1" @click="sortType = 0; sortRegisters()">
                            <v-icon>mdi-sort-descending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by descending</span>
                </v-tooltip>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Filter Current View"
                        prepend-inner-icon="mdi mdi-filter"
                        outlined
                        single-line
                        @keyup="filteredSearch"
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
            <v-col 
                style="padding-top: 10px; display: flex;"
                cols="12"
                lg="2"
                md="2"
                sm="12"
            >
                <v-combobox
                    v-model="daysRemaingSelected"
                    :items="listDaysRemaining"
                    label="Days Remaining"
                    item-text="description"
                    clearable
                    outlined
                    @change="filteredSearch"
                    dense
                ></v-combobox>
            </v-col>
        </v-row>

        <div id="divHeaders" style="width: 100%; overflow-x: auto" class="sticky" @scroll="syncScroll">

            <v-row 
                class="rowWrap"
            >
                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle incomingProjects">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-file-document-check-outline</v-icon>
                                <label class="pipelineWhiteColor">Incoming Projects</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdIncomingProjects }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    v-if="userLoggedGetters.profile.drafting != 1"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle draftReview">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-file-document-check-outline</v-icon>
                                <label class="pipelineWhiteColor">Draft Review</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdDraftReview }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle drafting">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-notebook-edit-outline</v-icon>
                                <label class="pipelineWhiteColor">Drafting</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdDrafting }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2  pipelineTitle approvals">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-note-edit-outline</v-icon>
                                <label class="pipelineWhiteColor">Approvals</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdApproval }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle finalPlan">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-check-circle-outline</v-icon>
                                <label class="pipelineWhiteColor">Final Plan</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdFinalPlan }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle finalPlan">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-check-decagram-outline</v-icon>
                                <label class="pipelineWhiteColor">Engineering</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdEngineering }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

            </v-row>

        </div>

        <div id="divContents" style="width: 100%; overflow-x: auto" @scroll="syncScroll">

            <v-row 
                class="rowWrap"
            >
                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >   
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listIncomingProjectsFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="item.deadlineProgress"
                            :color="getDueDateColor(item)"
                            style="margin-top: 20px"
                            height="7"
                        >
                        </v-progress-linear>
                        <v-card-text
                            :class="checkProjectHoldAndGetBackgroundColor(item)"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="checkProjectHoldAndGetBackgroundColor(item)"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="checkProjectHoldAndGetBackgroundColor(item) + ' justify-center'"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-alert-octagon
                                        </v-icon>
                                    </template>
                                    <span>{{ item.notesHold }} - {{ item.dateHoldDays }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    v-if="userLoggedGetters.profile.drafting != 1"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >   
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listDraftReviewFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="item.deadlineProgress"
                            :color="getDueDateColor(item)"
                            style="margin-top: 20px"
                            height="7"
                        >
                        </v-progress-linear>

                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_REVIEW, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="userLoggedGetters.profile.readOnlyDrafting != 1"
                                            :to="{ name: 'checkAllItems', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-check-circle-outline</v-icon> Check</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: { id: item.idService, idServiceProject: item.id, disabledMode: true} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="downloadFile(URL_ATTACHMENT_ENGINEERING_SHEET, item.id, 'application/pdf', PDF_NAMES.ENGINEER_SHEET)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-google-spreadsheet</v-icon> Engineering Sheet</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.dueDateFormatted }}
                                <span :style="'color:' + getDueDateColor(item)">( {{ item.dueDateDeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_REVIEW, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_REVIEW, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="dueDateIsLate(item)"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listDraftingFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="item.deadlineProgress"
                            :color="getDueDateColor(item)"
                            style="margin-top: 20px"
                            height="7"
                        >
                        </v-progress-linear>

                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="userLoggedGetters.profile.readOnlyDrafting != 1"
                                            :to="{ name: 'uploadFiles', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-briefcase-upload-outline</v-icon> Upload Design</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="userLoggedGetters.profile.drafting != 1"
                                            @click="showDialogChooseTeam(item.id)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-account-supervisor</v-icon> Change Team</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: { id: item.idService, idServiceProject: item.id, disabledMode: true} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="downloadFile(URL_ATTACHMENT_ENGINEERING_SHEET, item.id, 'application/pdf', PDF_NAMES.ENGINEER_SHEET)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-google-spreadsheet</v-icon> Engineering Sheet</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="backPreviousStep(item.id, PROJECT_STATUS.DRAFTING_REVIEW)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to previous step</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip 
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.dueDateFormatted }}
                                <span :style="'color:' + getDueDateColor(item)">( {{ item.dueDateDeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="dueDateIsLate(item)"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>

                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listApprovalFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="item.deadlineProgress"
                            :color="getDueDateColor(item)"
                            style="margin-top: 20px"
                            height="7"
                        >
                        </v-progress-linear>
                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_APPROVAL, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="userLoggedGetters.profile.budgetControl == 1 || userLoggedGetters.profile.systemAdmin == 1"
                                            :to="{ name: 'signature', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-file-sign</v-icon> Collect Signatures</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: { id: item.idService, idServiceProject: item.id, disabledMode: true} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="downloadFile(URL_ATTACHMENT_ENGINEERING_SHEET, item.id, 'application/pdf', PDF_NAMES.ENGINEER_SHEET)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-google-spreadsheet</v-icon> Engineering Sheet</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="backPreviousStep(item.id, PROJECT_STATUS.DRAFTING)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to previous step</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip 
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.dueDateFormatted }}
                                <span :style="'color:' + getDueDateColor(item)">( {{ item.dueDateDeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_APPROVAL, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_APPROVAL, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="dueDateIsLate(item)"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listFinalPlanFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="item.deadlineProgress"
                            :color="getDueDateColor(item)"
                            style="margin-top: 20px"
                            height="7"
                        >
                        </v-progress-linear>
                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_FINAL_PLAN, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="userLoggedGetters.profile.readOnlyDrafting != 1 && userLoggedGetters.profile.drafting != 1"
                                            :to="{ name: 'finalPlan', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-overscan</v-icon> Overview</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: { id: item.idService, idServiceProject: item.id, disabledMode: true} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="downloadFile(URL_ATTACHMENT_ENGINEERING_SHEET, item.id, 'application/pdf', PDF_NAMES.ENGINEER_SHEET)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-google-spreadsheet</v-icon> Engineering Sheet</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="backPreviousStep(item.id, PROJECT_STATUS.DRAFTING_APPROVAL)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to previous step</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip 
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.dueDateFormatted }}
                                <span :style="'color:' + getDueDateColor(item)">( {{ item.dueDateDeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_FINAL_PLAN, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_FINAL_PLAN, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="dueDateIsLate(item)"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                </v-col>
                
                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listEngineeringFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="item.status > PROJECT_STATUS.DRAFTING_ENGINEERING ? 100 : item.deadlineProgress"
                            :color="getDueDateColor(item)"
                            style="margin-top: 20px"
                            height="7"
                        >
                        </v-progress-linear>
                        <v-card-text
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_ENGINEERING, item)}`"
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.idService)">{{ item.idFormatted }}</label>

                            <div
                                v-if="checkHoldSituation(item) == false || userLoggedGetters.profile.systemAdmin == 1"
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.idService} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="checkHoldSituation(item) == false && userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'H')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-else-if="userLoggedGetters.profile.systemAdmin == 1"
                                            @click="showDialogHold(item.id, 'R')"
                                        >
                                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="userLoggedGetters.profile.readOnlyDrafting != 1 && userLoggedGetters.profile.drafting != 1 && item.status == 12"
                                            :to="{ name: 'engineering', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-check-circle-outline</v-icon> Check</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: { id: item.idService, idServiceProject: item.id, disabledMode: true} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="downloadFile(URL_ATTACHMENT_ENGINEERING_SHEET, item.id, 'application/pdf', PDF_NAMES.ENGINEER_SHEET)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-google-spreadsheet</v-icon> Engineering Sheet</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="backPreviousStep(item.id, PROJECT_STATUS.DRAFTING_FINAL_PLAN)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to previous step</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <v-card
                                class="d-flex background-transparent margin-top-10"
                                flat
                                tile
                            >
                                <v-card
                                    v-if="item.kindWork == 1"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                color="var(--color__main)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.kindWorkFirstLetter }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.kindWorkDescription }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    v-if="checkHoldSituation(item)"
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-tooltip 
                                        v-if="checkHoldSituation(item)"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                v-on="on"
                                                :color="getSituationColor(item.situation)"
                                                outlined
                                                dark
                                                small
                                            >
                                                {{ item.situationDescription }} - {{ item.dateHoldDays }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.notesHold }}</span>
                                    </v-tooltip>
                                </v-card>

                                <v-card
                                    class="pa-2 background-transparent"
                                    flat
                                    tile
                                >
                                    <v-chip
                                        :color="getClassificationColor(item.classification)"
                                        outlined
                                        dark
                                        small
                                    >
                                        {{ item.classification }} - {{ item.classificationDescription }}
                                    </v-chip>
                                </v-card>
                            </v-card>

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb;" v-if="item.status <= PROJECT_STATUS.DRAFTING_NEEDS_APPROVAL">
                                Deadline: <strong>{{ item.dueDateFormatted }}
                                <span :style="'color:' + getDueDateColor(item)">( {{ item.dueDateDeadlineDaysDescription }} )</span> </strong>
                            </h5>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions
                            :class="`${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_ENGINEERING, item)}`"
                        >
                            <v-col 
                                class="margin-top"
                                cols="6"
                                lg="7"
                                md="6"
                                sm="6"
                            >
                                <v-icon left>mdi mdi-account-tie</v-icon>
                                <label >{{ item.sellerName }}</label>
                                
                                <br />
                                <div class="marginInfoBottom" />

                                <v-icon left>mdi mdi-file-sign</v-icon>
                                <label>{{ item.nameProject }}</label>
                            </v-col>
                            <v-col 
                                class="contractValue"
                                cols="6"
                                lg="5"
                                md="6"
                                sm="6"
                            >
                                <label>{{ item.contractValueFormatted }}</label>
                            </v-col>
                        </v-card-actions>

                        <v-card-actions 
                            :class="`justify-center ${checkProjectHoldAndGetBackgroundColor(item)} ${checkStatusCard(PROJECT_STATUS.DRAFTING_ENGINEERING, item)}`"
                        >
                            <v-col
                                v-if="checkHoldSituation(item)"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__red)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-close-octagon
                                        </v-icon>
                                    </template>
                                    <span>Hold [{{ item.dateHoldDays }}] - {{ item.notesHold }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="item.status <= PROJECT_STATUS.DRAFTING_NEEDS_APPROVAL && item.dueDateDeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                cols="2"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on">
                                            <v-badge
                                                :content="item.qtyUnRead"
                                                :value="item.qtyUnRead"
                                                left
                                                overlap
                                                color="var(--color__red)"
                                            >
                                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(item.idService)">mdi mdi-bell-outline</v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Unviewed notes</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        
        <DialogHold 
            :id="dialogHoldResume.id"
            :type="dialogHoldResume.type"
            v-on:methodConfirmToCall="dialogHoldResume.methodConfirm"
            :showDialogHold.sync="dialogHoldResume.show"
        />

        <DialogChooseTeam
            :idAux="dialogChooseTeam.idAux"
            :idServiceProject="dialogChooseTeam.idServiceProject"
            :showDialogTeam.sync="dialogChooseTeam.show"
        />
        
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { filterValuePart, orderBy } from '@/utilities/Utils';
    import DialogHold from "@/components/Services/DialogHold";
    import DialogChooseTeam from '@/components/Services/DialogChooseTeam.vue';
    import { 
        PROJECT_STATUS, 
        URL_ATTACHMENT_ENGINEERING_SHEET,
        PDF_NAMES
    } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            DialogHold,
            DialogChooseTeam
        },

        data: () => ({

            filter: {
                fastSearch: ''
            },

            idServiceProject: 0,

            listIncomingProjects: [],
            listIncomingProjectsFiltered: [],
            qtdIncomingProjects: 0,

            listDraftReview: [],
            listDraftReviewFiltered: [],
            qtdDraftReview: 0,

            listDrafting: [],
            listDraftingFiltered: [],
            qtdDrafting: 0,

            listApproval: [],
            listApprovalFiltered: [],
            qtdApproval: 0,

            listFinalPlan: [],
            listFinalPlanFiltered: [],
            qtdFinalPlan: 0,

            listEngineering: [],
            listEngineeringFiltered: [],
            qtdEngineering: 0,

            showDialogDrafterTeam: false,
            listDrafters: [],
            drafterTeam: [],

            sortSelected: null,
            sortType: 0,
            listSortOptions: [
                { id: 1, description: 'Registration Date', listField: ['id'] },
                { id: 2, description: 'Deadline', listField: ['dueDateDeadlineDays', 'dueDateDeadlineDays', 'dueDateDeadlineDays', 'dueDateDeadlineDays', 'dueDateDeadlineDays'] },
                { id: 3, description: 'Classification', listField: ['classification'] },
                { id: 4, description: 'Contract Amount', listField: ['contractValue'] }
            ],

            dialogHoldResume: {
                id: 0,
                type: "H",
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogChooseTeam: {
                idAux: 0,
                idServiceProject: 0,
                show: false
            },

            daysRemaingSelected: null,
            listDaysRemaining: [],

            PROJECT_STATUS,
            URL_ATTACHMENT_ENGINEERING_SHEET,
            PDF_NAMES
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' })
        },

        methods: {

            showImportantMessage(item) {
                return item.importantMessage != null && 
                       item.importantMessage != undefined && 
                       item.importantMessage.toString().trim() != ""
            },

            checkStatusCard(idStatusCard, itemProject) {

                if (itemProject != null && itemProject.status < itemProject.statusOriginal) {

                    if (itemProject.status == idStatusCard) {
                        return 'card-with-problem'
                    }
                    else {
                        return 'card-waiting-solve-problem'
                    }
                }
            },

            checkHoldSituation(item) {
                return item.situation == 2
            },

            checkProjectHoldAndGetBackgroundColor(item) {
                return this.checkHoldSituation(item) ? '': '';
            },

            goToServiceDetails(id) {
                this.$router.push({ name: "serviceDetails", params: {id: id} });
            },
			
            getSituationColor (situation) {

                let color = "";

                switch (situation) {

                    case 1:
                        color = 'var(--color__main)';
                        break;

                    case 2:
                        color = 'var(--color__red)';
                        break;

                    case 3:
                        color = 'var(--color__completed)';
                        break;
                }

                return color
            },
			
            getClassificationColor (classification) {

                let color = "";

                switch (classification) {

                    case 0:
                        color = 'var(--color__silver)';
                        break;

                    case 1:
                        color = 'var(--color__red)';
                        break;

                    case 2:
                        color = 'var(--color__alert)';
                        break;

                    case 3:
                        color = 'var(--color__main)';
                        break;

                    case 4:
                        color = 'var(--color__blue)';
                        break;
                }

                return color
            },
            
            haveGateCode(item) {
                return item.customerAddressGateCode != null && item.customerAddressGateCode != undefined && item.customerAddressGateCode != ''
            },

            filteredSearch() {

                const filter = this.filter.fastSearch.toLowerCase();
                
                this.listIncomingProjectsFiltered = filterValuePart(this.listIncomingProjects, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);
                this.listDraftReviewFiltered = filterValuePart(this.listDraftReview, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);
                this.listDraftingFiltered = filterValuePart(this.listDrafting, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);
                this.listApprovalFiltered = filterValuePart(this.listApproval, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);
                this.listFinalPlanFiltered = filterValuePart(this.listFinalPlan, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);
                this.listEngineeringFiltered = filterValuePart(this.listEngineering, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);

                if (this.daysRemaingSelected != null && this.daysRemaingSelected != undefined) {
                    this.listIncomingProjectsFiltered = filterValuePart(this.listIncomingProjectsFiltered, this.daysRemaingSelected.description, ['dueDateDeadlineDays']);
                    this.listDraftingFiltered = filterValuePart(this.listDraftingFiltered, this.daysRemaingSelected.description, ['dueDateDeadlineDays']);
                    this.listApprovalFiltered = filterValuePart(this.listApprovalFiltered, this.daysRemaingSelected.description, ['dueDateDeadlineDays']);
                    this.listFinalPlanFiltered = filterValuePart(this.listFinalPlanFiltered, this.daysRemaingSelected.description, ['dueDateDeadlineDays']);
                    this.listEngineeringFiltered = filterValuePart(this.listEngineeringFiltered, this.daysRemaingSelected.description, ['dueDateDeadlineDays']);
                }

                return null;
            },

            sortRegisters() {

                let field = ['id'];

                if (this.sortSelected != null && this.sortSelected != undefined) {
                    field = this.sortSelected.listField;
                }

                if (field.length == 1) {
                    this.listIncomingProjectsFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listDraftReviewFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listDraftingFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listApprovalFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listFinalPlanFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listEngineeringFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                }
                else {
                    this.listDraftReviewFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listDraftingFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[1]) });
                    this.listApprovalFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[2]) });
                    this.listFinalPlanFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[3]) });
                    this.listEngineeringFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[4]) });
                }
            },

            async checkNotes(idServiceProject) {
                this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                    idService: 0, 
                    idServiceProject, 
                    onlyCheckExists: true, 
                    idStatus: 0, 
                    idPanel: 0, 
                    idStage: 0,
                    idUser: 0
                } );
                if (this.listNote.length > 0) return 1; else return 0;
            },

            async checkProductsIcons(idServiceProject) {
                return await this.$store.dispatch("productModule/ListProductsWithIconInProject", { idService: 0, idServiceProject });
            },

            async checkIcons() {
                this.listIncomingProjectsFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })

                this.listDraftReviewFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })
                
                this.listDraftingFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })
                
                this.listApprovalFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })
                
                this.listFinalPlanFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })
                
                this.listEngineeringFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })
            },

            async getRegisters() {
                const response = await this.$store.dispatch("serviceModule/ListPipelineDrafting");
                this.sortSelected = this.listSortOptions[0];

                if (response != null && response != undefined) {

                    if (response.listPipeline && response.listPipeline != null && response.listPipeline != undefined) {

                        this.listIncomingProjects = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.SALES_CONTRACTED)];
                        if (this.listIncomingProjects != null && this.listIncomingProjects != undefined) {
                            this.qtdIncomingProjects = this.listIncomingProjects.length;
                        }
                        this.listIncomingProjectsFiltered = [...this.listIncomingProjects];

                        this.listDraftReview = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.DRAFTING_REVIEW)];
                        if (this.listDraftReview != null && this.listDraftReview != undefined) {
                            this.qtdDraftReview = this.listDraftReview.length;
                        }
                        this.listDraftReviewFiltered = [...this.listDraftReview];

                        this.listDrafting = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.DRAFTING || pip.status == this.PROJECT_STATUS.DRAFTING_NEEDS_APPROVAL)];
                        if (this.listDrafting != null && this.listDrafting != undefined) {
                            this.qtdDrafting = this.listDrafting.length;
                        }
                        this.listDraftingFiltered = [...this.listDrafting];

                        this.listApproval = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.DRAFTING_APPROVAL)];
                        if (this.listApproval != null && this.listApproval != undefined) {
                            this.qtdApproval = this.listApproval.length;
                        }
                        this.listApprovalFiltered = [...this.listApproval];

                        this.listFinalPlan = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.DRAFTING_FINAL_PLAN)];
                        if (this.listFinalPlan != null && this.listFinalPlan != undefined) {
                            this.qtdFinalPlan = this.listFinalPlan.length;
                        }
                        this.listFinalPlanFiltered = [...this.listFinalPlan];

                        this.listEngineering = [...response.listPipeline.filter(pip => pip.status >= this.PROJECT_STATUS.DRAFTING_ENGINEERING)];
                        if (this.listEngineering != null && this.listEngineering != undefined) {
                            this.qtdEngineering = this.listEngineering.length;
                        }
                        this.listEngineeringFiltered = [...this.listEngineering];

                        const listDaysRemaining = response.listPipeline.map(item => { return { description: item.dueDateDeadlineDays } } );

                        this.listDaysRemaining = [...new Set(listDaysRemaining.sort((a, b) => { return orderBy(a, b, 1, 'description') }))];

                        await this.checkIcons();
                    }
                }
            },

            async showDialogHold(id, type) {
                this.dialogHoldResume = {
                    id,
                    show: true,
                    type,
                    methodConfirm: ( () => {  this.getRegisters(); })
                }
            },

            async showDialogChooseTeam(id) {
                this.dialogChooseTeam = {
                    idAux: Math.random(),
                    idServiceProject: id,
                    show: true
                }
            },

            async backPreviousStep(id, status) {
                
                const updateStatusRequest = {
                    id,
                    status
                };

                const result = await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", result.message);
                    this.getRegisters();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            },

            dueDateIsLate(item) {
                return item.dueDateDeadlineDays < 2;
            },

            getDueDateColor(item) {
                let color = item.dueDateDeadlineDays == 2 ? 'var(--color__pipeline_deadline_warning)' : item.dueDateDeadlineDays < 2 ? 'var(--color__pipeline_deadline_over_due)' : 'var(--color__pipeline_deadline_ok)'

                if (item.status == this.PROJECT_STATUS.SALES_CONTRACTED) {
                    color = 'var(--color__pipeline_incoming_projects)'
                }
                else if (item.status > this.PROJECT_STATUS.DRAFTING_ENGINEERING) {
                    color = 'var(--color__pipeline_deadline_ok)'
                }

                return color;
            }
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        },
    })
</script>

<style scoped>

    .v-card__actions {
        padding: 0px;
    }

    .draftReview {
        background-color: var(--color__pipeline_draft_review);
    }

    .drafting {
        background-color: var(--color__pipeline_draft)
    }

    .approvals {
        background-color: var(--color__pipeline_approvals)
    }

    .finalPlan {
        background-color: var(--color__pipeline_finalPlan);
    }

    .margin-top {
        margin-top: 0px;
    }
</style>