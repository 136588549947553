<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="2"
                md="3"
                sm="12"
            >
                <h3>Pipeline Construction - BBQ</h3>
            </v-col>
            <v-col 
                style="padding-top: 10px; display: flex;"
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <v-combobox
                    v-model="sortSelected"
                    :items="listSortOptions"
                    label="Sort By"
                    item-text="description"
                    item-value="id"
                    clearable
                    outlined
                    @change="sortRegisters"
                    dense
                ></v-combobox>

                <v-tooltip bottom v-if="sortType == 0">
                    <template v-slot:activator="{ on }" v-if="sortType == 0">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 0" @click="sortType = 1; sortRegisters()">
                            <v-icon>mdi-sort-ascending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by ascending</span>
                </v-tooltip>

                <v-tooltip bottom v-if="sortType == 1">
                    <template v-slot:activator="{ on }" v-if="sortType == 1">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 1" @click="sortType = 0; sortRegisters()">
                            <v-icon>mdi-sort-descending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by descending</span>
                </v-tooltip>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <v-combobox
                    :items="listUser"
                    label="Search by Supervisor"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                    single-line
                    outlined
                    dense
                ></v-combobox>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <v-autocomplete
                    v-model="projectSelected"
                    :items="listProjects"
                    :loading="isLoading"
                    :search-input.sync="searchProject"
                    hide-selected
                    :no-data-text="textNoData"
                    item-text="description"
                    item-value="id"
                    placeholder="Card Search"
                    prepend-inner-icon="mdi mdi-magnify"
                    outlined
                    return-object
                    dense
                ></v-autocomplete>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Filter Current View"
                        prepend-inner-icon="mdi mdi-filter"
                        outlined
                        single-line
                        @keyup="filteredSearch"
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
            <v-col 
                style="padding-top: 10px; display: flex;"
                cols="12"
                lg="2"
                md="2"
                sm="12"
            >
                <v-combobox
                    v-model="daysRemaingSelected"
                    :items="listDaysRemaining"
                    label="Days Remaining"
                    item-text="description"
                    clearable
                    outlined
                    @change="filteredSearch"
                    dense
                ></v-combobox>
            </v-col>
        </v-row>

        <div id="divHeaders" style="width: 100%; overflow-x: auto" class="sticky" @scroll="syncScroll">

            <v-row 
                class="rowWrap"
            >
                <v-col 
                    v-for="(itemCard, index) in listCards" :key="index"
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-card>
                        <v-card-title :class="`text-h6 lighten-2 pipelineTitle ${itemCard.cssClass}`">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-card-text-outline</v-icon>
                                <label class="pipelineWhiteColor">{{ itemCard.title }}</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ itemCard.qtd }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

            </v-row>
        </div>

        <div id="divContents" style="width: 100%; overflow-x: auto" @scroll="syncScroll">
            <v-row 
                class="rowWrap"
            >
                <v-col 
                    v-for="(itemCard, index) in listCards" :key="index"
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >   
                    <v-card 
                        class="pipelineDetails"
                        v-for="(itemProject, index) in itemCard.listItems" :key="index"
                    >
                        <ConstructionCard
                            :id="itemProject.id"
                            :itemProject="{...itemProject}"
                            :itemCard="{...itemCard}"
                            :firstStatus="PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.id"
                            :lastStatus="PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.id"
                            v-on:methodConfirmToCall="getRegisters()"
                        />
                    </v-card>
                    
                </v-col>

            </v-row>
        </div>
        
    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { filterValuePart, orderBy } from '@/utilities/Utils';
    import ConstructionCard from "@/components/Services/ConstructionCard";
    import { PROJECT_STATUS } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ConstructionCard
        },

        data: () => ({

            filter: {
                fastSearch: ''
            },

            idPanel: 7,
            listUser: [],

            listCards:[],

            listUndergroundInspection: [],
            listUndergroundInspectionFiltered: [],
            qtdUndergroundInspection: 0,

            listFrameInstallation: [],
            listFrameInstallationFiltered: [],
            qtdFrameInstallation: 0,

            listFrameAndTieDownInspection: [],
            listFrameAndTieDownInspectionFiltered: [],
            qtdFrameAndTieDownInspection: 0,

            listRoughElectricInspection: [],
            listRoughElectricInspectionFiltered: [],
            qtdRoughElectricInspection: 0,

            listDrywallInstallation: [],
            listDrywallInstallationFiltered: [],
            qtdDrywallInstallation: 0,

            listDrywallAndTieDownInspection: [],
            listDrywallAndTieDownInspectionFiltered: [],
            qtdDrywallAndTieDownInspection: 0,

            listCladding: [],
            listCladdingFiltered: [],
            qtdCladding: 0,

            listFinalElectric: [],
            listFinalElectricFiltered: [],
            qtdFinalElectric: 0,

            listFinalEGP: [],
            listFinalEGPFiltered: [],
            qtdFinalEGP: 0,

            listFinalInspection: [],
            listFinalInspectionFiltered: [],
            qtdFinalInspection: 0,

            sortSelected: null,
            sortType: 0,
            listSortOptions: [
                { id: 1, description: 'Registration Date', listField: ['id'] },
                { id: 2, description: 'Deadline', listField: ['datePermittingStep1DeadlineDays', 'datePermittingStep2DeadlineDays', 'datePermittingStep3DeadlineDays', 'datePermittingStep4DeadlineDays', 'datePermittingStep4DeadlineDays'] },
                { id: 3, description: 'Responsible', listField: ['permitterUserName'] },
                { id: 4, description: 'Supervisor', listField: ['id'] },
                { id: 5, description: 'Contract Amount', listField: ['contractValue'] }
            ],
            textNoData: 'Type to search a project...',
            projectSelected: null,
            searchProject: null,
            listProjects: [],
            isLoading: false,

            daysRemaingSelected: null,
            listDaysRemaining: [],

            PROJECT_STATUS
        }),

        watch: {
            async searchProject(search) {
                
                if (search == null || search == undefined || search.toString().trim() == '') {
                    this.listProjects = [];
                    this.textNoData = "Type to search a project...";
                }
                else {

                    if (this.listProjects.length <= 0) {
                        this.textNoData = "No projects found";
                    }

                    this.isLoading = true;

                    this.listProjects = await this.$store.dispatch("serviceModule/ListProjectCompact", search);

                    this.isLoading = false;
                }
            }
        },

        methods: {

            filteredSearch() {

                const filter = this.filter.fastSearch.toLowerCase();                
                
                this.listCards.forEach(itemCard => {

                    itemCard.listItems = filterValuePart(itemCard.listItemsClone, filter, ['step', 'customerAddress', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);

                    if (this.daysRemaingSelected != null && this.daysRemaingSelected != undefined) {
                        itemCard.listItems = filterValuePart(itemCard.listItems, this.daysRemaingSelected.description, ['dueDateDeadlineDays']);
                    }
                })

                return null;
            },

            sortRegisters() {

                let field = ['id'];

                if (this.sortSelected != null && this.sortSelected != undefined) {
                    field = this.sortSelected.listField;
                }
                
                this.listCards.forEach(itemCard => {

                    itemCard.listItems.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });

                })
            },

            async checkNotes(idServiceProject) {
                
                this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                    idService: 0, 
                    idServiceProject, 
                    onlyCheckExists: true, 
                    idStatus: 0, 
                    idPanel: 0,
                    idStage: 0, 
                    idUser: 0 
                } );

                if (this.listNote.length > 0) return 1; else return 0;
            },

            async checkProductsIcons(idServiceProject) {
                return await this.$store.dispatch("productModule/ListProductsWithIconInProject", { idService: 0, idServiceProject });
            },

            async checkIcons() {

                this.listCards.forEach(itemCard => {

                    itemCard.listItems.forEach(async itemProject => {
                        itemProject.showIconNotes = await this.checkNotes(itemProject.id);
                        itemProject.listProductsWithIcons = await this.checkProductsIcons(itemProject.id);

                        this.$forceUpdate();
                    });

                })
            },

            async getRegisters() {

                let listUserResponse = await this.$store.dispatch("userModule/List");
                this.sortSelected = this.listSortOptions[0];

                this.listUser = listUserResponse.filter(usr => usr.constructionSupervisor == 1);

                let response = await this.$store.dispatch("serviceModule/ListPipelineConstruction");

                if (response != null && response != undefined) {

                    this.listCards = [];

                    if (response.listPipeline && response.listPipeline != null && response.listPipeline != undefined) {

                        this.listUndergroundInspection = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.id)];
                        if (this.listUndergroundInspection != null && this.listUndergroundInspection != undefined) {
                            this.qtdUndergroundInspection = this.listUndergroundInspection.length;
                        }
                        this.listUndergroundInspectionFiltered = [...this.listUndergroundInspection];

                        this.listFrameInstallation = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEINSTALLATION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEINSTALLATION.id)];
                        if (this.listFrameInstallation != null && this.listFrameInstallation != undefined) {
                            this.qtdFrameInstallation = this.listFrameInstallation.length;
                        }
                        this.listFrameInstallationFiltered = [...this.listFrameInstallation];


                        this.listFrameAndTieDownInspection = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEANDTIEDOWNINSPECTION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEANDTIEDOWNINSPECTION.id)];
                        if (this.listFrameAndTieDownInspection != null && this.listFrameAndTieDownInspection != undefined) {
                            this.qtdFrameAndTieDownInspection = this.listFrameAndTieDownInspection.length;
                        }
                        this.listFrameAndTieDownInspectionFiltered = [...this.listFrameAndTieDownInspection];


                        this.listRoughElectricInspection = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_ROUGHELECTRICINSPECTION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_ROUGHELECTRICINSPECTION.id)];
                        if (this.listRoughElectricInspection != null && this.listRoughElectricInspection != undefined) {
                            this.qtdRoughElectricInspection = this.listRoughElectricInspection.length;
                        }
                        this.listRoughElectricInspectionFiltered = [...this.listRoughElectricInspection];


                        this.listDrywallInstallation = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLINSTALLATION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLINSTALLATION.id)];
                        if (this.listDrywallInstallation != null && this.listDrywallInstallation != undefined) {
                            this.qtdDrywallInstallation = this.listDrywallInstallation.length;
                        }
                        this.listDrywallInstallationFiltered = [...this.listDrywallInstallation];


                        this.listDrywallAndTieDownInspection = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLANDTIEDOWNINSPECTION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLANDTIEDOWNINSPECTION.id)];
                        if (this.listDrywallAndTieDownInspection != null && this.listDrywallAndTieDownInspection != undefined) {
                            this.qtdDrywallAndTieDownInspection = this.listDrywallAndTieDownInspection.length;
                        }
                        this.listDrywallAndTieDownInspectionFiltered = [...this.listDrywallAndTieDownInspection];


                        this.listCladding = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_CLADDINGANDCOUNTERTOPINSTALLATION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_CLADDINGANDCOUNTERTOPINSTALLATION.id)];
                        if (this.listCladding != null && this.listCladding != undefined) {
                            this.qtdCladding = this.listCladding.length;
                        }
                        this.listCladdingFiltered = [...this.listCladding];


                        this.listFinalElectric = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICINSTALLATION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICINSTALLATION.id)];
                        if (this.listFinalElectric != null && this.listFinalElectric != undefined) {
                            this.qtdFinalElelistFinalElectric = this.listFinalElectric.length;
                        }
                        this.listFinalElectricFiltered = [...this.listFinalElectric];


                        this.listFinalEGP = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICFINALGASFINALPLUMBINGINSPECTION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICFINALGASFINALPLUMBINGINSPECTION.id)];
                        if (this.listFinalEGP != null && this.listFinalEGP != undefined) {
                            this.qtdFinalElelistFinalEGP = this.listFinalEGP.length;
                        }
                        this.listFinalEGPFiltered = [...this.listFinalEGP];


                        this.listFinalInspection = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.id)];
                        if (this.listFinalInspection != null && this.listFinalInspection != undefined) {
                            this.qtdFinalElelistFinalInspection = this.listFinalInspection.length;
                        }
                        this.listFinalInspectionFiltered = [...this.listFinalInspection];

                        const listPipelines = [...this.listUndergroundInspectionFiltered,
                                                ...this.listFrameInstallationFiltered,
                                                ...this.listFrameAndTieDownInspectionFiltered,
                                                ...this.listRoughElectricInspectionFiltered,
                                                ...this.listDrywallInstallationFiltered,
                                                ...this.listDrywallAndTieDownInspectionFiltered,
                                                ...this.listCladdingFiltered,
                                                ...this.listFinalElectricFiltered,
                                                ...this.listFinalEGPFiltered,
                                                ...this.listFinalInspectionFiltered
                                              ];

                        const listDaysRemaining = listPipelines.map(item => { return { description: item.dueDateDeadlineDays } } );

                        this.listDaysRemaining = [...new Set(listDaysRemaining.sort((a, b) => { return orderBy(a, b, 1, 'description') }))];

                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.id,
                            qtd: this.listUndergroundInspection.length,
                            listItems: this.listUndergroundInspection,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 32,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: false,
                                workOrder: false,
                                inspection: false,
                                showAdvanceButton: false,
                                showBackButton: false,
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEINSTALLATION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEINSTALLATION.id,
                            qtd: this.listFrameInstallation.length,
                            listItems: this.listFrameInstallation,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 33,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: true,
                                showBackButton: false
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEANDTIEDOWNINSPECTION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FRAMEANDTIEDOWNINSPECTION.id,
                            qtd: this.listFrameAndTieDownInspection.length,
                            listItems: this.listFrameAndTieDownInspection,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 34,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: true,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_ROUGHELECTRICINSPECTION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_ROUGHELECTRICINSPECTION.id,
                            qtd: this.listRoughElectricInspection.length,
                            listItems: this.listRoughElectricInspection,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 35,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: true,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLINSTALLATION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLINSTALLATION.id,
                            qtd: this.listDrywallInstallation.length,
                            listItems: this.listDrywallInstallation,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 36,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: false,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLANDTIEDOWNINSPECTION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_DRYWALLANDTIEDOWNINSPECTION.id,
                            qtd: this.listDrywallAndTieDownInspection.length,
                            listItems: this.listDrywallAndTieDownInspection,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 37,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: false,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_CLADDINGANDCOUNTERTOPINSTALLATION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_CLADDINGANDCOUNTERTOPINSTALLATION.id,
                            qtd: this.listCladding.length,
                            listItems: this.listCladding,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 38,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: false,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICINSTALLATION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICINSTALLATION.id,
                            qtd: this.listFinalElectric.length,
                            listItems: this.listFinalElectric,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 49,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: false,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICFINALGASFINALPLUMBINGINSPECTION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALELECTRICFINALGASFINALPLUMBINGINSPECTION.id,
                            qtd: this.listFinalEGP.length,
                            listItems: this.listFinalEGP,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 40,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: false,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.description,
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.id,
                            qtd: this.listFinalInspection.length,
                            listItems: this.listFinalInspection,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: 41,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: false,
                                showBackButton: true
                            },
                        })
                
                        this.listCards.forEach(itemCard => {

                            itemCard.listItemsClone = [...itemCard.listItems];

                        })                        

                        await this.checkIcons();
                    }
                }
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        },
    })
</script>

<style scoped>

    .colorPipeline {
        background-color: var(--color__construction_dashboard_bbq);
    }

    .v-progress-linear__determinate {
        background-color: var(--color__construction_dashboard_bbq) !important; 
    }
</style>