import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticStyle:{"padding-bottom":"20px"}},[_c(VCol,{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"6","lg":"10","md":"8","sm":"6"}},[_c('h3',[_vm._v("Open Work Orders")])]),_c(VCol,{staticStyle:{"text-align":"right"},attrs:{"cols":"6","lg":"2","md":"4","sm":"6"}},[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({on:{"click":function($event){_vm.showDialogOptions = true}}},'v-btn',attrs,false),on),[_vm._v(" Export PDF ")])]}}])},[_c('span',[_vm._v("Export Report to PDF")])])],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.getRegisters.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Quick Search","prepend-inner-icon":"mdi mdi-magnify","outlined":"","single-line":"","single-expand":true,"show-expand":"","dense":""},on:{"click:prepend-inner":_vm.getRegisters,"click:row":_vm.showDetails},model:{value:(_vm.filter.fastSearch),callback:function ($$v) {_vm.$set(_vm.filter, "fastSearch", $$v)},expression:"filter.fastSearch"}})],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.filteredSearch,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"single-expand":true,"show-expand":"","no-data-text":"No Record Found"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.showDetails},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank"},on:{"click":function($event){return _vm.goToWorkOrder(item.id)}}},on),[_vm._v(" "+_vm._s(item.id)+" ")])]}}],null,true)},[_c('span',[_vm._v("Open Work order")])])]}},{key:"item.projectDescription",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank"},on:{"click":function($event){return _vm.goToServiceDetails(item.idService)}}},on),[_vm._v(" "+_vm._s(item.projectDescription)+" ")])]}}],null,true)},[_c('span',[_vm._v("Job Details")])])]}},{key:"item.dateRegisterFormatted",fn:function(ref){
var item = ref.item;
return [_c('label',{style:(item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : '')},[_vm._v(" "+_vm._s(item.dateRegisterFormatted)+" ")])]}},{key:"item.targetDateFormatted",fn:function(ref){
var item = ref.item;
return [_c('label',{style:(item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : '')},[_vm._v(" "+_vm._s(item.targetDateFormatted)+" ")])]}},{key:"item.projectStatusDescription",fn:function(ref){
var item = ref.item;
return [_c('label',{style:(item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : '')},[_vm._v(" "+_vm._s(item.projectStatusDescription)+" ")])]}},{key:"item.responsibleName",fn:function(ref){
var item = ref.item;
return [_c('label',{style:(item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : '')},[_vm._v(" "+_vm._s(item.responsibleName)+" ")])]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_c('label',{style:(item.targetDateExpired == 1 ? 'color: var(--color__red) !important; ' : '')},[_vm._v(" "+_vm._s(item.age)+" ")])]}},{key:"item.statusDescription",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getStatusColor(item),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.statusDescription)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticStyle:{"width":"100%","margin":"10px"}},[_c('h3',[_vm._v("Scope of Work")]),_c('br'),_c('span',[_vm._v(_vm._s(item.scopeOfWork))])])])]}}])})],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}}),_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":"500","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showDialogOptions),callback:function ($$v) {_vm.showDialogOptions=$$v},expression:"showDialogOptions"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 lighten-2 formDialog",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_vm._v(" Options ")]),_c(VCardText,{staticStyle:{"margin-top":"20px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c(VSwitch,{attrs:{"label":"Show scope of work?","value":true,"color":"var(--color__main)"},model:{value:(_vm.showScopeOfWork),callback:function ($$v) {_vm.showScopeOfWork=$$v},expression:"showScopeOfWork"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c(VBtn,{staticClass:"mx-2 dialogButtonDismiss",attrs:{"outlined":""},on:{"click":function($event){_vm.showDialogOptions = false}}},[_vm._v(" Dismiss ")]),_c(VBtn,{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.downloadFile(_vm.URL_REPORT_WORK_ORDER, _vm.showScopeOfWork == null ? false : _vm.showScopeOfWork, 'application/pdf', 'OpenWorkOrders.pdf');}}},[_vm._v(" Export ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }