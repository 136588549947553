<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Final Plan - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >                    
                <JobInformation 
                    :id="serviceProjectResponse.idService"
                    :project="projectSelected"
                    :showProjects="false"
                    :showJobSummary="true"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="true"
                    :showDisabledClassification="false"
                />
            </v-col>
        </v-row>

        <v-row>
            <!-- POOL PLAN -->
            <v-col
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Pool Plan
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    v-for="(itemFile, index) in poolPlanFiles.filter(item => item.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="12"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card 
                                        v-if="itemFile.id != 0"
                                        max-width="200"
                                    >
                                        <v-card
                                            class="cardDownload"
                                            @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                        >
                                            <v-card-text>
                                                <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                <span class="imageFileName">{{ itemFile.fileName }}</span>
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="serviceDraftingUploadFilesRequest.poolPlanNotes"
                                        rows="4"
                                        row-height="23"
                                        auto-grow
                                        outlined
                                        disabled
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>

            <!-- DESIGN FILES -->
            <v-col
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Design Files
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    v-for="(itemFile, index) in designFiles.filter(item => item.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="6"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card 
                                        v-if="itemFile.id != 0"
                                        max-width="200"
                                    >
                                        <v-card
                                            class="cardDownload"
                                            @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                        >
                                            <v-card-text>
                                                <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                <span class="imageFileName">{{ itemFile.fileName }}</span>
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="serviceDraftingUploadFilesRequest.designFilesNotes"
                                        rows="4"
                                        row-height="23"
                                        auto-grow
                                        outlined
                                        disabled
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>

            <!-- BARBECUE -->
            <v-col
                v-if="serviceProjectResponse.bbq == 1"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Barbecue
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    v-for="(itemFile, index) in bbqFiles.filter(item => item.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="12"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card 
                                        v-if="itemFile.id != 0"
                                        max-width="200"
                                    >
                                        <v-card
                                            class="cardDownload"
                                            @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                        >
                                            <v-card-text>
                                                <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                <span class="imageFileName">{{ itemFile.fileName }}</span>
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="serviceDraftingUploadFilesRequest.barbecueNotes"
                                        rows="4"
                                        row-height="23"
                                        auto-grow
                                        outlined
                                        disabled
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>

            <!-- PERGOLAS -->
            <v-col
                v-if="serviceProjectResponse.pergola == 1"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Pergolas
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    v-for="(itemFile, index) in pergolasFiles.filter(item => item.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="12"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card 
                                        v-if="itemFile.id != 0"
                                        max-width="200"
                                    >
                                        <v-card
                                            class="cardDownload"
                                            @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                        >
                                            <v-card-text>
                                                <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                <span class="imageFileName">{{ itemFile.fileName }}</span>
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="serviceDraftingUploadFilesRequest.pergolasNotes"
                                        rows="4"
                                        row-height="23"
                                        auto-grow
                                        outlined
                                        disabled
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>

            <!-- DRIVE WAY -->
            <v-col
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Driveway
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    v-for="(itemFile, index) in driveWayFiles.filter(item => item.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="12"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card 
                                        v-if="itemFile.id != 0"
                                        max-width="200"
                                    >
                                        <v-card
                                            class="cardDownload"
                                            @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                        >
                                            <v-card-text>
                                                <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                <span class="imageFileName">{{ itemFile.fileName }}</span>
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="serviceDraftingUploadFilesRequest.driveWayNotes"
                                        rows="4"
                                        row-height="23"
                                        auto-grow
                                        outlined
                                        disabled
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <div 
            class="text-center"
        >            
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-row>
                        <v-col 
                            style="text-align: right"
                            cols="6"
                            lg="6"
                            md="6"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                        @click="cancel"
                                    >
                                        Back
                                    </v-btn>
                                </template>
                                <span>Back</span>
                            </v-tooltip>
                        </v-col>
                        
                        <v-col 
                            style="text-align: left"
                            cols="6"
                            lg="6"
                            md="6"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        @click="chooseEngineer"
                                    >
                                        Send to Engineering
                                    </v-btn>
                                </template>
                                <span>Send to Engineering</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </div>

		<v-dialog
			v-model="showDialogEngineer"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Choose a Engineer
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>

						<v-col 
                            cols="12"
							lg="12"
							md="12"
							sm="12"
						>
                            <label>Engineer</label>
                            <v-combobox
                                v-model="engineerSelected"
                                :items="listContractor" 
                                item-text="contractorCategoryDescription"
                                item-value="id"
                                clearable
                                outlined
                                @change="getListContacts()"
                                dense
                            ></v-combobox>
						</v-col>
					</v-row>
					<v-row
                        v-if="listContractorContact != null && listContractorContact != undefined && listContractorContact.length > 0"
                    >
						<v-col 
                            cols="12"
							lg="12"
							md="12"
							sm="12"
						>
                            <label>Choose a Contact</label>
                            <v-combobox
                                v-model="engineerContactSelected"
                                :items="listContractorContact"
                                item-text="nameAndEmail"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
						</v-col>
					</v-row>

				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="showDialogEngineer = false"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="confirmFinalPlan()"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import JobInformation from "@/components/Services/JobInformation";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT, 
        CATEGORY_TEAM, 
        URL_ATTACHMENT 
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            JobInformation
        },

        mixins: [Helpers],

        data: () => ({

            URL_ATTACHMENT,

            steps: 1,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,

            serviceDraftingUploadFilesRequest: {
                id: 0,
                idService: 0,
                poolPlanNotes: "",
                designFilesNotes: "",
                imagesNotes: "",
                barbecueNotes: "",
                pergolasNotes: "",
                driveWayNotes: "",
            },

            poolPlanListFiles: [],
            poolPlanFiles: [],

            designListFiles: [],
            designFiles: [],

            bbqListFiles: [],
            bbqFiles: [],

            pergolasListFiles: [],
            pergolasFiles: [],

            driveWayListFiles: [],
            driveWayFiles: [],

            showDialogEngineer: false,
            listContractor: [],
            listContractorContact: [],
            engineerSelected: null,
            engineerContactSelected: null,

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }
                    
                    const responseDraftingUploadFiles = await this.$store.dispatch("serviceModule/GetDraftingUploadFilesById", this.id);

                    if (responseDraftingUploadFiles.result.id != 0) {
                        this.serviceDraftingUploadFilesRequest = responseDraftingUploadFiles.result;

                        this.poolPlanListFiles = [];
                        this.poolPlanFiles = [];

                        this.designListFiles = [];
                        this.designFiles = [];

                        this.bbqListFiles = [];
                        this.bbqFiles = [];

                        this.pergolasListFiles = [];
                        this.pergolasFiles = [];

                        this.driveWayListFiles = [];
                        this.driveWayFiles = [];
                        
                        const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.DRAFTING.value, idKey: this.serviceDraftingUploadFilesRequest.id });
 

                        const poolPlanListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_POOL_PLAN.value);
                        if (poolPlanListFiles != null && poolPlanListFiles != undefined) {

                            poolPlanListFiles.forEach(itemFile => {

                                this.poolPlanFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        const designListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_DESIGN_SPECIFICATION.value);
                        if (designListFiles != null && designListFiles != undefined) {

                            designListFiles.forEach(itemFile => {

                                this.designFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        const bbqListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_BARBECUE.value);
                        if (bbqListFiles != null && bbqListFiles != undefined) {

                            bbqListFiles.forEach(itemFile => {

                                this.bbqFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        const pergolasListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_PERGOLAS.value);
                        if (pergolasListFiles != null && pergolasListFiles != undefined) {

                            pergolasListFiles.forEach(itemFile => {

                                this.pergolasFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        const driveWayListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_DRIVEWAY.value);
                        if (driveWayListFiles != null && driveWayListFiles != undefined) {

                            driveWayListFiles.forEach(itemFile => {

                                this.driveWayFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/drafting/pipeline" });
            },

            async chooseEngineer() {
                this.listContractor = await this.$store.dispatch("contractorModule/ListByCategory", 1); //All Engineer´s
                this.showDialogEngineer = true;
            },

            async getListContacts() {

                this.listContractorContact = []
                    
                if (this.engineerSelected != null || this.engineerSelected != undefined) {
                    this.listContractorContact = await this.$store.dispatch("contractorModule/ListContacts", this.engineerSelected.id);
                }
            },

            async confirmFinalPlan() {

                if (this.engineerSelected == null || this.engineerSelected == undefined || this.engineerSelected.id == 0 ||
                    this.engineerContactSelected == null || this.engineerContactSelected == undefined || this.engineerContactSelected.id == 0) {
                    this.showToast("error", "Error!", "You must select an Engineer/Contact");
                }
                else {

                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: `An email will be sent to Engineer [${this.engineerContactSelected.nameAndEmail}] with load calculation and pool plan documents, confirm your decision?`,
                        methodConfirm: this.save,
                        params: 0
                    };
                }
            },

            async save() {

                this.showLoading();
                
                const listTeamRequest = [{
                    id: 0,
                    idServiceProject: this.id,
                    categoryTeam: CATEGORY_TEAM.ENGINEER.value,
                    idUserOrContractor: this.engineerContactSelected.id
                }];

                const result = await this.$store.dispatch("serviceModule/AssignTeam", listTeamRequest);

                if (result.success) {

                    let result = await this.$store.dispatch("serviceModule/SendEmailEngineer", this.id);

                    if (result.success) {
                        
                        let updateStatusRequest = {
                            id: this.id,
                            status: 12 //Engineering
                        };

                        let result = await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);
                        

                        if (result.success) {
                            this.showToast("success", "Success!", "E-mail sent and final plan updated successfully");
                        }
                        else {
                            this.showToast("error", "Error sending email!", result.message);
                        }
                    }
                    else {
                        this.showToast("error", "Error updating status!", result.message);
                    }
                }
                else {
                    this.showToast("error", "Error to assign team!", result.message);
                }

                this.cancel();

                this.hideLoading();
            },
        },

        async created() {

            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 170px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

    .cardDownload {
        text-align: center;
    }
</style>